import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MeetingCalendar from "../components/calendar/MeetingCalendar";
import Meeting from "../components/meeting/Meeting";
import SitePage from "../components/sitepage/SitePage";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
export default function CalendarRoute() {

    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const navigate = useNavigate();

    const onSelectMeeting = (eventitem) => {
        //console.log(eventitem.id);
        //setSelectedMeeting(eventitem.id);
        navigate("/meeting/" + eventitem.id);
    }
    // sitepageid for calendar
    // 66b9aba3-7384-4188-98fa-f7b49f48e308

    return (
        <>
            <Container>
                <SitePage id={"66b9aba3-7384-4188-98fa-f7b49f48e308"} />
                <MeetingCalendar onClick={onSelectMeeting}></MeetingCalendar>
            </Container>
        </>
    );
}