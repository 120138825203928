import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import authConfig from "../../authConfig";
import MonthSelector from './MonthSelector';
import YearSelector from './YearSelector';
import Button from 'react-bootstrap/Button';

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import {
    MonthlyBody,
    MonthlyDay,
    MonthlyCalendar,
    MonthlyNav,
    DefaultMonthlyEventItem,
    useMonthlyCalendar
} from '@zach.codes/react-calendar';
//import '@zach.codes/react-calendar/dist/calendar-tailwind.css';
import '@zach.codes/react-calendar/dist/calendar-tailwind-no-reset.css';
import { useSelector, useDispatch } from 'react-redux';
import { setDate } from '../../redux/calendarDateSlice';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react";
/*
 * 
 * */
export const GovMonthlyEventItem = (props) => {
    const handleClick = (e) => {
        if (props.onClick) {
            props.onClick(props.item)
        }
    }
    return (
        <li className="rc-py-2">
            <div className="rc-flex rc-text-sm rc-flex-1 rc-justify-between">
                <div className="rc-font-medium">
                    <a onClick={handleClick} alt={props.title}>{props.title}</a>
                </div>
                <p className="rc-text-gray-500">{props.date}</p>
            </div>
        </li>
    );
};
/*
 * 
 * */
export const AltMonthlyNav = (props) => {
    let { currentMonth, onCurrentMonthChange } = useMonthlyCalendar();
    const { t, i18n } = useTranslation();
    const handleMonthChange = (newdate) => {
        if (props.onChange) {
            props.onChange(newdate);
        }
    }
    return (
        <div className="rc-flex rc-justify-center rc-mb-4">
            <Button variant="success"
                onClick={() => onCurrentMonthChange(subMonths(currentMonth, 1))}
                className="cursor-pointer"
            >
                {t("Previous")}
            </Button>
            {/*<div className="ml-4 mr-4 w-32 text-center">*/}
            &nbsp;
            <MonthSelector date={currentMonth} onChange={(d) => onCurrentMonthChange(d)} />&nbsp;
            <YearSelector date={currentMonth} onChange={(d) => onCurrentMonthChange(d)} />&nbsp;

            &nbsp;
            {/*</div>*/}
            <Button variant="success"
                onClick={() => onCurrentMonthChange(addMonths(currentMonth, 1))}
                className="cursor-pointer"
            >
                {t("Next")}
            </Button>
        </div>
    );
};
/*
 * {format(
                    currentMonth,
                    getYear(currentMonth) === getYear(new Date()) ? 'LLLL' : 'LLLL yyyy'
'LLLL yyyy',
    { locale: i18n.language == 'en' ? enGB : cy }
                )}*/
/*
 * 
 * */
function MeetingCalendar(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const date = useSelector((state) => state.calendardate.value)
    const dispatch = useDispatch();
    //let dispatching = false;
    const handleDateChange = newdate => {
        //if (!dispatching) {
        //dispatching = true;
        const nd = newdate;
        const d = new Date(newdate);
        dispatch(setDate(d));
        //dispatching = false;
        //}
    };
    /*const requestData = useCallback(
        async event => {

        },
        [props, instance, account]
    );*/

    const language = useSelector((state) => state.language.value);

    //requestData();
    useEffect(() => {
        console.log("useEffect:requestData:" + loading);

        if (!loading) {
            loadData();
        }
    }, [date, language]);

    function loadData() {
        setError(null)
        setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeetingCalendar + "?start=" + formatISO(startOfMonth(date), { representation: 'date' }) + "&end=" + formatISO(endOfMonth(date), { representation: 'date' }) + "&lng=" + language + "&committee=all")
                                .then(function (response) {
                                    //console.log("Request response:", response.data.result)
                                    //var result = props.printResult(response.data)
                                    let data = [];
                                    for (var ev of response.data.result) {
                                        //console.log("D=" + ev.start +", ISO=" + parseISO(ev.start))
                                        ev.date = parseISO(ev.start);//moment(ev.start).toDate();
                                        ev.start = parseISO(ev.start);//moment(ev.start).toDate();
                                        data.push(ev);
                                    }
                                    //console.log(data);
                                    setData(data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getMeetingCalendar + "?start=" + formatISO(startOfMonth(date), { representation: 'date' }) + "&end=" + formatISO(endOfMonth(date), { representation: 'date' }) + "&lng=" + language + "&committee=all")
                .then(function (response) {
                    //console.log("Request response:", response.data.result)
                    //var result = props.printResult(response.data)
                    let data = [];
                    for (var ev of response.data.result) {
                        //console.log("D=" + ev.start + ", ISO=" + parseISO(ev.start))
                        ev.date = parseISO(ev.start);//moment(ev.start).toDate();
                        ev.start = parseISO(ev.start);//moment(ev.start).toDate();
                        data.push(ev);
                    }
                    console.log(data);
                    setData(data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }
    function setCurrentMonth(date) {
        handleDateChange(date);
    }
    const selectEvent = (eventitem) => {
        if (props.onClick) {
            props.onClick(eventitem);
        }
        //console.log(eventitem);
    }
    function renderDay(ev) {
        console.log("render day" + ev);
    }
    return (
        <div>
            <MonthlyCalendar
                currentMonth={date}
                onCurrentMonthChange={date => setCurrentMonth(date)}
                locale={i18n.language == 'en' ? enGB : cy}
            >
                <AltMonthlyNav />
                <MonthlyBody
                    events={data}
                    renderDay={renderDay}
                    onClick={(ev) => selectEvent}
                    omitDays={[
                        0,
                        6
                    ]}

                >
                    <MonthlyDay
                        renderDay={data =>
                            data.map((item, index) => (
                                <GovMonthlyEventItem
                                    key={index}
                                    title={(item.status ? (authConfig.showStatusTypes.includes(item.status) ? t(item.status) + " - " : "") : "") + item.title}
                                    // Format the date here to be in the format you prefer
                                    date={format(item.date, 'k:mm')}
                                    onClick={selectEvent}
                                    item={item}
                                />
                            ))
                        }
                    />
                </MonthlyBody>
            </MonthlyCalendar>
        </div>
    );
}

export default MeetingCalendar;
//export const aaMeetingCalendar = () => (
//    <>
//            <Calendar
//                events={[]}
//                localizer={localizer}
//                defaultDate={new Date(2021, 5, 8)}
//                style={{ height: 700 }}
//            />
//    </>
//);