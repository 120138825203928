import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import Container from 'react-bootstrap/Container';
import SitePage from '../sitepage/SitePage';
import { Link, NavLink } from "react-router-dom";
import SecureFileLink from "../meeting/SecureFileLink";

function Member(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);

    useEffect(() => {
        console.log("useEffect:requestData:" + loading);
        if (!loading) {
            loadData();
        }
    }, [language, props]);

    function loadData() {
        setError(null)
        setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMemberSummary + "/" + props.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    response.data.member.terms.sort((a, b) => {
                                        return new Date(a.from) > new Date(b.from);
                                    });
                                    response.data.committees.sort((a, b) => {
                                        return a.nameEn > b.nameEn ? 1 : -1;
                                    });
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getMemberSummary + "/" + props.id)
                .then(function (response) {
                    console.log(response.data);
                    response.data.member.terms.sort((a, b) => {
                        return new Date(a.from) > new Date(b.from);
                    });
                    response.data.otherAuthorityMembers.sort((a, b) => {
                        return (a.person.firstname + " " + a.person.lastname) > (b.person.firstname + " " + b.person.lastname) ? 1 :
                            (a.person.firstname + " " + a.person.lastname) < (b.person.firstname + " " + b.person.lastname) ? -1 : 0;
                    });
                    response.data.committees.sort((a, b) => {
                        return a.nameEn > b.nameEn ? 1 : -1;
                    });
                    response.data.outsideBodies.sort((a, b) => {
                        return a.nameEn > b.nameEn ? 1 : -1;
                    });
                    setData(response.data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }
    function getPublicFiles(publicFiles) {
        publicFiles.sort((a, b) => {
            return a.positionIndex > b.positionIndex ? 1 : -1;
        });
        var categories = [];
        for (var file of publicFiles) {
            if (!categories.includes(file.category)) {
                categories.push(file.category);
            }
        }

        return (
            categories.map((category, index) => (
                <>
                    <p>
                        <b key={index}>
                            {category}
                        </b>
                    </p>
                    <ul>
                        {publicFiles.filter(function (el) { return el.category == category }).map((file, fileindex) => (
                            <li>
                                <SecureFileLink
                                    url={authConfig.api.urls.getMemberPublicFile + "/" + file.attachedFile.id + "/" + file.attachedFile.fileName}
                                    filename={file.attachedFile.fileName}>{file.attachedFile.fileName}
                                </SecureFileLink>
                            </li>
                        ))}
                    </ul>
                </>
            )))
    }
    return data === null || loading || data.member === null ?
        (<></>) :
        (
            <>
                <Container>

                    {data.member && data.member.person ?
                        (
                            <>
                                <h1>{t(data.member.person.title) + " " + data.member.person.firstname + " " + data.member.person.lastname}</h1>
                                <p>
                                    {data.member.photo ? (<img
                                        style={{ maxHeight: "280px" }}
                                        src={authConfig.api.urls.getMemberPhoto + "/" + data.member.photo.id + "/" + data.member.photo.fileName}
                                    />) : ''}

                                </p>
                                <>
                                    <p>
                                        <b>{t("Title")}: </b>
                                        {language === 'cy' ? data.member.titleCy : data.member.titleEn}
                                    </p>
                                    <p>
                                        <b>{t("Appointing Authority")}: </b>
                                        {language === 'cy' ? data.member.appointingAuthorityCy : data.member.appointingAuthorityEn}
                                    </p>
                                    {data.otherAuthorityMembers && data.otherAuthorityMembers.length > 0 ? (
                                        <>
                                            <p>
                                                <b>{t("Other Members representing this Appointing Authority")}</b>
                                            </p>
                                            <ul>
                                                {data.otherAuthorityMembers.map((member, index) => (
                                                    <li key={index}>
                                                        <Link to={"/member/" + member.id}>{t(member.person.title) + " " + member.person.firstname + " " + member.person.lastname}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : ''}

                                    {data.committees && data.committees.length > 0 ? (
                                        <>
                                            <p>
                                                <b>{t("Committee Appointments")}</b>
                                            </p>
                                            <ul>
                                                {data.committees.map((committee, index) => (
                                                    <li key={index}>
                                                        <Link to={"/committee/" + committee.id}>{language === 'cy' ? committee.nameCy : committee.nameEn}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : ''}


                                    {data.member.terms && data.member.terms.length > 0 ? (
                                        <>
                                            <p>
                                                <b>{t("Terms of Office")}</b>
                                            </p>
                                            <ul>
                                                {data.member.terms.map((term, index) => (
                                                    <li key={index}>{format(new Date(term.from), 'dd/MM/yyyy')} - {format(new Date(term.to), 'dd/MM/yyyy')}</li>
                                                ))}
                                            </ul>
                                        </>
                                    ) : ''}

                                    {data.member.publicFiles && data.member.publicFiles.length > 0 ? (
                                        <>
                                            {getPublicFiles(data.member.publicFiles)}
                                        </>
                                    ) : ''}

                                    {data.outsideBodies && data.outsideBodies.length > 0 ?
                                        (
                                            <>
                                                <p>
                                                    <b>{t("Appointments to outside bodies")}</b>
                                                </p>
                                                <ul>
                                                    {data.outsideBodies.map((outsidebody, index) => (
                                                        <li key={index}>
                                                            <Link to={"/outsidebody/" + outsidebody.id}>{language === 'cy' ? outsidebody.nameCy : outsidebody.nameEn}</Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </>
                                        )
                                        :
                                        ''
                                    }

                                    <p>
                                        <b>{t("Additional Information")}</b>
                                    </p>
                                    <div dangerouslySetInnerHTML={{ __html: language === 'cy' ? data.member.descriptionCy : data.member.descriptionEn }} />
                                </>

                            </>
                        )
                        :
                        ''
                    }
                </Container>
            </>
        );
}
export default Member;