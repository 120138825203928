import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import authConfig from "../../authConfig";
import SecureFileLink from "./SecureFileLink";
function MeetingFiles(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);

    return (
        <>
            {language === 'cy' ?
                (
                    <ul>
                        {
                            props.meeting.filesCy ? props.meeting.filesCy.map((file, index) => (

                                <li key={index}>
                                    {file.isRestricted ?
                                        (<SecureFileLink
                                            url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                            filename={file.attachedFile.fileName}>{file.attachedFile.fileName}
                                        </SecureFileLink>) :
                                        (<a
                                            href={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                            target="_blank"
                                        >
                                            {file.attachedFile.name}
                                        </a>)}
                                    
                                </li>

                            )) : ''
                        }
                    </ul>
                )
                :
                (
                    <ul>
                        {
                            props.meeting.filesEn ? props.meeting.filesEn.map((file, index) => (
                                <li key={index}>
                                    {file.isRestricted ?
                                        (<SecureFileLink
                                            url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                            filename={file.attachedFile.fileName}>{file.attachedFile.name}
                                        </SecureFileLink>) :
                                        (<a
                                            href={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                            target="_blank"
                                        >
                                            {file.attachedFile.name}
                                        </a>)}

                                </li>
                            )) : ''
                        }
                    </ul>
                )
            }

        </>
    )



}
export default MeetingFiles;