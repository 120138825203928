import { useEffect, useState, useCallback } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import PersonSelector from "../person/PersonSelector.js";
import OutsideBody from "./OutsideBody.js";

function OutsideBodyRepresentativesEditor(props) {

    const [person, setPerson] = useState(null);


    const handleNewPersonChange = (o) => {
        setPerson(o);
    }

    const handleAdd = () => {

        let newrepresentatives = [...props.value,person];
        if (props.onChange) {
            props.onChange(newrepresentatives);
        }
    }
    const handleRemove = (o) => {
        let newrepresentatives = [];
        for (var representative of props.value) {
            if (representative.id === o.id) {
                // skip
            } else {
                newrepresentatives.push(representative);
            }
        }
        if (props.onChange) {
            props.onChange(newrepresentatives);
        }
    }

    return props.value ? (
        <>
            <Row>
                <Col xs="2">
                    Add representative
                </Col>
                <Col xs="4">
                    <PersonSelector value={person} onChange={handleNewPersonChange} membersonly={true} />
                </Col>
                <Col xs="4">
                    <Button
                        onClick={handleAdd}
                        disabled={false}
                    >
                        Add
                    </Button>
                </Col>
                <Col>

                </Col>
            </Row>
            {props.value.length > 0 ? (
                <b>Representatives</b>
            ) : ''}
            {props.value.sort((a, b) => {
                return (a.firstname + " " + a.lastname) > (b.firstname + " " + b.lastname) ? 1 : -1
            }).map((representative, index) => (
                <Row key={index}>
                    <Col xs="8">
                        {
                            representative.title + " " +
                            representative.firstname + " " +
                            representative.lastname
                        }
                    </Col>
                    <Col xs="2"><Button variant="danger" size="sm" onClick={() => { handleRemove(representative); }}><FaTrashAlt /></Button></Col>
                </Row>
            ))}
        </>
    ) : (<></>)

}

export default OutsideBodyRepresentativesEditor;