import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
//import MeetingCalendar from "../components/calendar/MeetingCalendar";
//import Meeting from "../components/meeting/Meeting";
import { Routes, Route, useParams } from 'react-router-dom';
import OutsideBody from '../components/outsidebody/OutsideBody';
import Container from 'react-bootstrap/Container';

export default function OutsideBodyRoute() {

    //const [selectedMeeting, setSelectedMeeting] = useState(null);
    const onSelectOutsideBody = (eventitem) => {
        //setSelectedMeeting(eventitem.id);
    }
    const { id } = useParams();

    return (
        <>
            <Container>
            {id != null ?
                (<OutsideBody id={id }></OutsideBody>)
                :
                (<div>No Outside Body Found
                    {id}</div>)
                }
            </Container>
        </>
    );
}