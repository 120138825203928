import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

import SitePage from '../sitepage/SitePage';
import { Link, NavLink } from "react-router-dom";

import AddNewMeeting from '../meeting/AddNewMeeting'
import MeetingListPopup from '../meeting/MeetingListPopup'
import PersonSelector from "../person/PersonSelector";
import ConfigItemHtmlPopup from "../configitem/ConfigItemHtmlPopup";
import EditTranslationsPopup from "../translation/EditTranslationsPopup"
import EditPersonPopup from "../person/EditPersonPopup";
import EditCommitteePopup from "../committee/EditCommitteePopup";
import EditOutsideBodyPopup from "../outsidebody/EditOutsideBodyPopup";

import ConfigItemAttendanceOrder from "../configitem/ConfigItemAttendanceOrder";

function AdminPage(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const [person, setPerson] = useState(null);
    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);

    useEffect(() => {
        console.log("useEffect:requestData:" + loading);
        if (!loading) {
            // loadData();
        }
    }, [language, props]);

    function loadData() {
        setError(null)
        setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getOutsideBodiesPage)
                                .then(function (response) {
                                    console.log(response.data);
                                    response.data.outsideBodies.sort((a, b) => {
                                        return a.nameEn > a.nameEn;
                                    });
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }

    const handleSelectPerson = (o) => {
        setPerson(o);
    }

    //
    return (
        <>
            <Row xs={1} md={2} className="g-4">
                <Col>
                    <Card
                        bg="light"
                        text='dark'
                        className="mb-1"
                    >
                        <Card.Header>Meetings</Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>

                            <MeetingListPopup /><br />


                        </Card.Body>
                        <Card.Footer>
                            <AddNewMeeting />
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card
                        bg="light"
                        text='dark'
                        className="mb-1"
                    >
                        <Card.Header>People</Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <EditPersonPopup />
                        </Card.Body>
                        <Card.Footer>
                            <EditPersonPopup addnew={true} />
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card
                        bg="light"
                        text='dark'
                        className="mb-1"
                    >
                        <Card.Header>Committees</Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <EditCommitteePopup />
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <EditCommitteePopup addnew={true} />
                        </Card.Footer>
                    </Card>

                </Col>
                <Col>
                    <Card
                        bg="light"
                        text='dark'
                        className="mb-1"
                    >
                        <Card.Header>Outside Bodies</Card.Header>
                        <Card.Body>
                            <Card.Title></Card.Title>
                            <EditOutsideBodyPopup />
                            <Card.Text>

                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <EditOutsideBodyPopup addnew={true} />
                        </Card.Footer>
                    </Card>

                </Col>
                <Col>
                    <Card
                        bg="light"
                        text='dark'
                        className="mb-1"
                    >
                        <Card.Header>Config {authConfig.api.isDevMode ? "." : ""}</Card.Header>
                        <Card.Body>
                            <ConfigItemHtmlPopup name="pdf_frontpage_head" title="PDF Frontpage Head" />
                            <ConfigItemHtmlPopup name="pdf_frontpage_tail" title="PDF Frontpage Tail" />
                            <ConfigItemHtmlPopup name="minutes_pdf_frontpage_head" title="Minutes PDF Head" />
                            <ConfigItemHtmlPopup name="minutes_pdf_frontpage_tail" title="Minutes PDF Tail" />
                            <ConfigItemAttendanceOrder title="PDF Attendance Order" />
                            <EditTranslationsPopup />
                        </Card.Body>
                        <Card.Footer>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
export default AdminPage;