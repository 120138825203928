import { useEffect, useState, useCallback } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';

function MemberTermsEditor(props) {

    const [datefrom, setDatefrom] = useState(null);
    const [dateto, setDateto] = useState(null);


    const handleNewFromChange = (v) => {
        setDatefrom(v);
        console.log(v);
    }
    const handleNewToChange = (v) => {
        setDateto(v);
    }
    const handleAdd = () => {
        if (datefrom !== null && dateto !== null) {
            let newterms = [...props.value, { id: uuidv4, from: delocalizedIsoDate(datefrom), to: delocalizedIsoDate(dateto) }];
            if (props.onChange) {
                props.onChange(newterms);
            }
            setDatefrom(null);
            setDateto(null);
        }
    }
    const handleRemove = (o) => {
        let newterms = [];
        for (var term of props.value) {
            if (term.id === o.id) {
                // skip
            } else {
                newterms.push(term);
            }
        }
        if (props.onChange) {
            props.onChange(newterms);
        }
    }
    const handleFromChange = (v, t) => {
        let newterms = [];
        let newdate = null;
        try {
            newdate = delocalizedIsoDate(v);
            for (var term of props.value) {
                if (term.id === t.id) {
                    let newterm = { ...term }
                    newterm.from = newdate;
                    newterms.push(newterm);
                } else {
                    newterms.push(term);
                }
            }
            if (props.onChange) {
                props.onChange(newterms);
            }
        } catch {

        }
    }
    const handleToChange = (v, t) => {
        let newterms = [];
        let newdate = null;
        try {
            newdate = delocalizedIsoDate(v);
            for (var term of props.value) {
                if (term.id === t.id) {
                    let newterm = { ...term }
                    newterm.to = delocalizedIsoDate(v);
                    newterms.push(newterm);
                } else {
                    newterms.push(term);
                }
            }
            if (props.onChange) {
                props.onChange(newterms);
            }
        } catch { }
    }
    return props.value ? (
        <>
            <Row>
                <Col xs="2">
                    Add new term
                </Col>
                <Col xs="4">
                    <DatePicker label="From" onChange={handleNewFromChange} />
                </Col>
                <Col xs="4">
                    <DatePicker label="To" onChange={handleNewToChange} />
                </Col>
                <Col>
                    <Button
                        onClick={handleAdd}
                        disabled={datefrom === null || dateto === null }
                    >
                        Add
                    </Button>
                </Col>
            </Row>
            {props.value.length > 0 ? (
            <b>Terms</b>
            ): '' }
            {props.value.map((term, index) => (
                <Row key={index }>
                    <Col xs="4">
                        <DatePicker
                            label="From"
                            key={"from" + index}
                            name={"from" + index}
                            value={new Date(term.from)}
                            onChange={(v) => { handleFromChange(v, term); }}
                        />
                    </Col>
                    <Col xs="4">
                        <DatePicker
                            label="To"
                            key={"to" + index}
                            name={"to" + index}
                            value={ new Date(term.to) }
                            onChange={(v) => { handleToChange(v, term); }}
                        />
                    </Col>
                    <Col xs="2"></Col>
                    <Col xs="1"><Button variant="danger" onClick={() => { handleRemove(term); } }><FaTrashAlt /></Button></Col>
                </Row>
                ))}
        </>
    ): (<></>)

}

export default MemberTermsEditor;