import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import SitePage from '../sitepage/SitePage';
import { Link, NavLink, useNavigate } from "react-router-dom";

import CommitteeSelector from '../committee/CommitteeSelector';

import { DateTimePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate } from '../Util.js';

import Autocomplete from '../autocomplete/Autocomplete'

function AddNewMeeting(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [committee, setCommittee] = useState(null);
    const [titleEn, setTitleEn] = useState(null);
    const [titleCy, setTitleCy] = useState(null);
    const [start, setStart] = useState(null);
    const [isvalid, setIsvalid] = useState(false);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [newmeeting, setNewmeeting] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const [date, setDate] = useState(startOfMonth(Date.now()));



    const handleClose = () => setShow(false);
    const handleShow = () => {
        setNewmeeting({});
        setCommittee(null);
        setStart(null);
        setShow(true);
        setTitleEn('');
        setTitleCy('');
        setIsvalid(false);
        loadData();
    }

    useEffect(() => {
        //console.log("useEffect:requestData:" + loading);
        if (!loading) {
            //loadData();
        }
    }, [language, props]);

    function loadData(committeeid) {
        setError(null)

        //setNewmeeting({})
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeetingNew + (committeeid == null? '' : "?committeeid=" + committeeid ))
                                .then(function (response) {
                                    //console.log(response.data);
                                    setNewmeeting(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function addMeeting(){
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .post(authConfig.api.urls.getMeeting, newmeeting)
                                .then(function (response) {
                                    console.log(response);
                                    navigate("/meeting/edit/" + newmeeting.id);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    function validate() {
        setIsvalid(
            isValidDate(new Date(start)) &&
            committee !== null &&
            titleEn.trim() !== '' &&
            titleCy.trim() !== '');
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }
    const handleCommitteeChange = (o) => {
        //console.log(o);
        setCommittee(o);
        loadData(o.id);
        setTitleEn(o.nameEn);
        setTitleCy(o.nameCy);
        validate();
    }
    const handleTitleChangeEn = (o) => {
        if (o) {
            //console.log("handleTitleChangeEn!!");
            //console.log(o);
            setTitleEn(o.en);
            if (o.cy) {
                setTitleCy(o.cy);
            }
        }
        validate();
    }
    const handleTitleChangeCy = (o) => {
        setTitleCy(o.target.value);
        validate();
    }
    const handleStartChange = (t) => {
        console.log(t);
        setStart(t); 
        validate();
    }
    const handleSave = () => {
        newmeeting.committee = committee;
        console.log("-------");
        console.log(start);
        newmeeting.start = delocalizedIsoDate(start);
        console.log(newmeeting.start);
        console.log("---end---");
        newmeeting.titleEn = titleEn;
        newmeeting.titleCy = titleCy;
        addMeeting();
        
    }

    return (
        <>
            <Button variant="success" onClick={handleShow}>
                Add meeting
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add new meeting</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            Committee
                        </Col>
                        <Col>
                            <CommitteeSelector value={committee} onChange={handleCommitteeChange} />
                        </Col>
                    </Row>
                    {committee == null ? (<></>) : (<>
                        <Row>
                            <Col>
                                Start Date/Time
                            </Col>
                            <Col>
                                <DateTimePicker value={start} onChange={handleStartChange} />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Title (EN)
                            </Col>
                            <Col>
                                <Autocomplete
                                    url={authConfig.api.urls.getMeetingTitleLookup}
                                    value={titleEn}
                                    onChange={handleTitleChangeEn }
                                />
                                    {/*<MeetingTitleInput value={titleEn} onChange={handleTitleChangeEn} />*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Title (CY)
                            </Col>
                            <Col>
                                <Form.Control type="text" value={titleCy} onChange={handleTitleChangeCy} />
                            </Col>
                        </Row>
                    </>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSave} disabled={!isvalid}>
                        Save and Edit
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default AddNewMeeting;