
// getAttendanceSelectList
import React from 'react';

import AsyncSelect from 'react-select/async';
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';

import Creatable from "react-select/creatable"
import { withAsyncPaginate } from "react-select-async-paginate"

const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

function MembershipRoleSelector(props) {
    const { t, i18n } = useTranslation();

    //const isAuthenticated = useIsAuthenticated();
    //const { instance, accounts } = useMsal()
    //const account = useAccount(accounts[0] || {})

    const language = useSelector((state) => state.language.value);


    async function getDataAsync(search, page, offset) {
        let returnvalue = {};
        await axios
            .create({
                /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
            })
            .get(authConfig.api.urls.getCommitteeMembershipRoleSelectList + '?search=' + search + '&page=' + page + '&offset=0')
            .then(function (response) {
                console.log(response.data);
                /*response.data.agendaItems.sort((a, b) => {
                    return a.positionIndex - b.positionIndex;
                });*/
                returnvalue = response.data;
                return response.data;
            })
            .catch(function (error) {
                //setError("Error fetching data: " + error.message)
                console.error(error)
            })
            .then(function () {
                // always executed
                //setLoading(false)
            });
        return returnvalue;
    }


    const handleChange = (o) => {
        //console.log(o);
        if (props.onChange) {
            props.onChange(o.value);
        }
    }

    return (
        <>
            <CreatableAsyncPaginate
                value={props.value ? { label: props.value, value: props.value } : {}}
                loadOptions={
                    async (search, loadedOptions, { page }) => {
                        const responseJSON = await getDataAsync(search, page, 0);
                        return {
                            options: responseJSON.result,
                            hasMore: responseJSON.hasmore,
                            additional: {
                                page: page + 1,
                            },
                        };
                    }}
                onChange={handleChange}
                additional={
                    { page: 1, }
                }
                readOnly={false} />
        </>
    )

}

export default MembershipRoleSelector;