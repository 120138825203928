import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { PublicClientApplication } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import authConfig from "./authConfig"
import reportWebVitals from './reportWebVitals';
import './i18n';
import store from './redux/store'
import { Provider } from 'react-redux'
import { BrowserRouter } from "react-router-dom";

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import enGB from 'date-fns/locale/en-gb';
const msalInstance = new PublicClientApplication(authConfig.msal)


const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB }>
            <BrowserRouter>
                <MsalProvider instance={msalInstance}>

                    <App />
                </MsalProvider>
                </BrowserRouter>
            </LocalizationProvider>
        </Provider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();