import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from '../redux/languageSlice';
import Form from 'react-bootstrap/Form';

const LanguageSelector = () => {
    const { i18n } = useTranslation();
    //const [language, setLanguage] = useState('en');
    const language = useSelector((state) => state.language.value)
    const dispatch = useDispatch();

    const handleLangChange = evt => {
        const lang = evt.target.value;
        dispatch(setLanguage(lang));
        i18n.changeLanguage(lang);
    };

    return (
        <Form.Select size="" onChange={handleLangChange} value={language}>
            <option value="en">English</option>
            <option value="cy">Cymraeg</option>
        </Form.Select>
    );
};

export default LanguageSelector;