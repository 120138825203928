import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MeetingCalendar from "../components/calendar/MeetingCalendar";
import MeetingEditor from "../components/meeting/MeetingEditor";
import { Routes, Route, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';

export default function MeetingRoute() {

    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const onSelectMeeting = (eventitem) => {
        console.log(eventitem.id);
        setSelectedMeeting(eventitem.id);
    }
    const { id } = useParams();

    return (
        <>
            <Container>
                {id != null ?
                    (<div>
                        <MeetingEditor MeetingId={id} />
                    </div>)
                    :
                    (<div>No Meeting
                        {id}</div>)
                }
            </Container>
        </>
    );
}