import { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


import './autocomplete.css'

function Autocomplete(props) {

    const [show, setShow] = useState(false);
    const [options, setOptions] = useState([]);
    const [activeOption, setActiveOption] = useState(0);
    const [value, setValue] = useState({ en: props.value ? props.value : '', cy: '' });
    const [loading, setLoading] = useState(false);
    const [minquerylength, setMinquerylength] = useState(props.minQueryLength ? props.minQueryLength : 2);
    const [maxquerylength, setMaxquerylength] = useState(props.maxQueryLength ? props.maxQueryLength : 10);
    const [focus, setFocus] = useState(false);
    //const [skipblur, setSkipblur] = useState(false);
    let skipblur = false;
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const parentRef = useRef(null);
    const childrenRef = useRef(null);



    useEffect(() => {
        if (props.value && props.value !== value.en) {
            setValue({ en: props.value });
        }
    }, [props]);

    const onChange = (e) => {
        setValue({ en: e.target.value });
        if (e.target.value.length >= minquerylength) {
            handleSearch(e.target.value);
            setShow(true);
        }
    }
    const onKeyDown = (e) => {

    }
    const update = () => {
        if (props.onChange && value.en !== props.value) {
            //console.log(value);
            props.onChange(value);
        }
    }
    const onBlur = (e) => {
        //setFocus(false);


        setTimeout(() => {
            setFocus(false);
            setShow(false);
            if (!skipblur) {
                update();
            }
            skipblur = false;
        }, 200);

        if (value.length < maxquerylength) {

            //
        }

    }
    const onFocus = (e) => {
        setFocus(true);
        if (value.length < maxquerylength) {
            setShow(true);
        }
    }
    const onClick = (o) => {
        setValue(o);
        skipblur = true;
        if (props.onChange) {
            props.onChange(o);
        }
    }
    async function handleSearch(query) {
        //setBlurvalue(query);
        //setSelected([{ en: query, cy: query }]);
        let authresponse = null;
        let returnvalue = null;
        //setLoading(true);
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                await instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        authresponse = response;
                    })
                    .catch(error => {
                        //setError("Error getting token: " + error.message)
                        //console.error(error)
                        setLoading(false);
                    });
                if (authresponse) {
                    //console.log("Got access token:", authresponse);
                    await axios
                        .create({
                            headers: { Authorization: `Bearer ${authresponse.accessToken}` }
                        })
                        .get(props.url + '?query=' + query)
                        .then(function (response) {
                            if (response.data.length == 0) {
                                returnvalue = [{ en: query, cy: query }];
                            } else {
                                returnvalue = [{ en: query, cy: query }, ...response.data];
                            }
                            setOptions(response.data);
                            //return returnvalue;//response.data;
                        })
                        .catch(function (error) {
                            //setError("Error fetching data: " + error.message)
                            console.error(error)
                        })
                        .then(function () {
                            // always executed
                            setLoading(false)
                        })
                } else {
                    //setError("Empty token??")
                    console.error("no token acquired")
                }
            }
        }
        //setLoading(false);
    }

    return (
        <>
            <input
                className="form-control"
                type="text"
                value={value.en}
                onChange={onChange}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                onBlur={onBlur}
            />
            {show && focus ? (
                <ul className="suggestions">
                    {options.map((option, index) => (
                        <li key={index} onClick={(e) => { onClick(option) }}>{option.en}</li>
                    ))}
                </ul>
            ) : (<></>)}
        </>
    )

}
export default Autocomplete;