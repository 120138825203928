import { createSlice } from '@reduxjs/toolkit'
import {
    startOfMonth,
} from 'date-fns';

export const calendarDateSlice = createSlice({
    name: 'calendardate',
    initialState: {
        value: startOfMonth(Date.now()),
    },
    reducers: {
        setDate: (state, action) => {
            state.value = action.payload
        },
    },
})

export const { setDate } = calendarDateSlice.actions

export default calendarDateSlice.reducer