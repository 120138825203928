import { useEffect, useState, useCallback } from "react";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react";
import axios from "axios"
import authConfig from "../../authConfig";
import Button from 'react-bootstrap/Button';
import { FaDownload } from 'react-icons/fa';

function SecureFileLink(props) {
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const [loading, setLoading] = useState(false);

    async function handleDownload(downloadfile) {
        let authresponse = null;
        let returnvalue = null;
        //setLoading(true);
        if (isAuthenticated) {
            //console.log("#1",account,loading);
            if (account && loading == false) {
                setLoading(true)
                //console.log("#2");
                await instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        authresponse = response;
                        console.log("authresponse :)")
                        setLoading(false);
                    })
                    .catch(error => {
                        //setError("Error getting token: " + error.message)
                        //console.error(error)
                        console.log("authresponse fail :(")
                        setLoading(false);
                    });
            }
        }

        //let file = props.url;//'https://www.example.com/some-file.pdf';

        let headers = new Headers();
        if (authresponse) {
            headers.append('Authorization', 'Bearer ' + authresponse.accessToken);
        } else 
        {
            console.log("AUTHRESPONSENULL");
            
        }
        fetch(props.url, { headers })
            .then(response => response.blob())
            .then(blobby => {
                let objectUrl = window.URL.createObjectURL(blobby);
                if (!downloadfile) {
                    window.open(objectUrl, "_blank");
                } else {
                    let anchor = document.createElement("a");
                    document.body.appendChild(anchor);
                    anchor.href = objectUrl;
                    anchor.download = props.filename;
                    anchor.click();
                }



                window.URL.revokeObjectURL(objectUrl);
            });
    }
    return (
        <>
            {props.type && props.type === "button" ?
                (
                    <Button size="sm" variant="success" onClick={() => { handleDownload(false); }}>{props.children}</Button>) :
                (
                    <a href="#" onClick={() => { handleDownload(false); }}>{props.children}</a>)
            }
            &nbsp;
            <Button size="sm" variant="success" onClick={() => { handleDownload(true); }}><FaDownload /></Button>
        </>
    )
}
export default SecureFileLink;