import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { DatePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";
import CommitteeSelector from "./CommitteeSelector";
//import TitleSelector from "./TitleSelector";
//import PersonTypeSelector from "./PersonTypeSelector";
import Autocomplete from "../autocomplete/Autocomplete";
import MemberTermsEditor from "../member/MemberTermsEditor";
import CommitteeMembershipEditor from "./CommitteeMembershipEditor";

function EditCommitteePopup(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    //const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [committee, setCommittee] = useState(null);
    const [page, setPage] = useState("details");

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getCommittee + "/" + committee.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getCommittee + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    function addData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .post(authConfig.api.urls.getCommittee, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }
    const handleClose = () => {
        setShow(false);
        setData(null);
    }
    const handleShow = () => {
        setShow(true);
        if (props.addnew) {
            setData({
                id: uuidv4(),
                "isCurrent": true,
                "showInList": true,
                "decommissionedDate": null,
                "nameEn": "",
                "nameCy": "",
                "descriptionEn": "",
                "descriptionCy": "",
                "contactEn": "",
                "contactCy": "",
                "membership": [],
                "meetings": [],
                overridePdfHead: false,
                pdfHeadCy: "",
                pdfHeadEn: "",
                overridePdfTail: false,
                pdfTailCy: "",
                pdfTailEn: "",
                overrideMinutesPdfHead: false,
                minutesPdfHeadEn: "",
                minutesPdfHeadCy: "",
                overrideMinutesPdfTail: false,
                minutesPdfTailEn: "",
                minutesPdfTailCy: ""
            });
        } else {
            loadData();
        }

    }
    const handleSave = () => {
        if (props.addnew) {
            addData();
            handleClose();
        } else {
            saveData();
        }

    }

    const handleSelectCommittee = (o) => {
        setCommittee(o);
    }



    const handlePageChange = (p) => {
        setPage(p);
    }
    const handleMembershipChange = (o) => {
        data.membership = o;
        setData({ ...data });
    }
    /*
     * 
     * */

    const handleDescriptionChange = (html, lang) => {
        if (lang === "en") { data.descriptionEn = html; }
        if (lang === "cy") { data.descriptionCy = html; }
        setData({ ...data });
    }
    const handleContactChange = (html, lang) => {
        if (lang === "en") { data.contactEn = html; }
        if (lang === "cy") { data.contactCy = html; }
        setData({ ...data });
    }
    const handlePdfHeadChange = (html, lang) => {
        if (lang === "en") { data.pdfHeadEn = html; }
        if (lang === "cy") { data.pdfHeadCy = html; }
        setData({ ...data });
    }
    const handlePdfTailChange = (html, lang) => {
        if (lang === "en") { data.pdfTailEn = html; }
        if (lang === "cy") { data.pdfTailCy = html; }
        setData({ ...data });
    }
    const handleMinutesPdfHeadChange = (html, lang) => {
        if (lang === "en") { data.minutesPdfHeadEn = html; }
        if (lang === "cy") { data.minutesPdfHeadCy = html; }
        setData({ ...data });
    }
    const handleMinutesPdfTailChange = (html, lang) => {
        if (lang === "en") { data.minutesPdfTailEn = html; }
        if (lang === "cy") { data.minutesPdfTailCy = html; }
        setData({ ...data });
    }
    const handleNameEnChange = (e) => {
        data.nameEn = e.target.value;
        setData({ ...data });
    }
    const handleNameCyChange = (e) => {
        data.nameCy = e.target.value;
        setData({ ...data });
    }
    const handleDecommissionedDateChange = (v) => {
        console.log("decomdatechange:", v);
        data.decommissionedDate = delocalizedIsoDate(v);
        data.isCurrent = false;
        setData({ ...data });
    }
    const handleRemoveDecommissionedDate = () => {
        data.decommissionedDate = null;
        data.isCurrent = true;
        setData({ ...data });
    }
    const handleShowInListChange = (e) => {
        data.showInList = e.target.checked;
        setData({ ...data });
    }
    const handleOverridePdfHead = (e) => {
        data.overridePdfHead = e.target.checked;
        setData({ ...data });
    }
    const handleOverridePdfTail = (e) => {
        data.overridePdfTail = e.target.checked;
        setData({ ...data });
    }
    const handleOverrideMinutesPdfHead = (e) => {
        data.overrideMinutesPdfHead = e.target.checked;
        setData({ ...data });
    }
    const handleOverrideMinutesPdfTail = (e) => {
        data.overrideMinutesPdfTail = e.target.checked;
        setData({ ...data });
    }
    const handleAddMember = (o) => {

    }
    const handleRemoveMember = (o) => {

    }

   
    /*
     * 
     * */
    return (
        <>
            {props.addnew ?
                (
                    <Button variant="success" onClick={handleShow}>
                        Add committee
                    </Button>
                ) :
                (
                    <Row>
                        <Col xs="10">
                            <CommitteeSelector value={committee} onChange={handleSelectCommittee} />
                        </Col>
                        <Col xs="2">
                            <Button variant="success" onClick={handleShow} disabled={false}>
                                Edit
                            </Button>
                        </Col>
                    </Row >)
            }


            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.addnew ? "Add New Committee" : "Edit Committee"} - {data == null ? '' : data.nameEn}</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldtype-1`}
                                    type="radio"
                                    name="radio"
                                    value="details"
                                    checked={page === "details"}
                                    onClick={() => { handlePageChange("details"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Committee Details
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldtype-2`}
                                    type="radio"
                                    name="fieldtype"
                                    value="description"
                                    checked={page === "description"}
                                    onClick={() => { handlePageChange("description"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Description
                                </ToggleButton>
                                <ToggleButton
                                    key={3}
                                    id={`fieldtype-3`}
                                    type="radio"
                                    name="fieldtype"
                                    value="contact"
                                    checked={page === "contact"}
                                    onClick={() => { handlePageChange("contact"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Contact
                                </ToggleButton>
                                <ToggleButton
                                    key={4}
                                    id={`fieldtype-4`}
                                    type="radio"
                                    name="fieldtype"
                                    value="membership"
                                    checked={page === "membership"}
                                    onClick={() => { handlePageChange("membership"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Membership
                                </ToggleButton>
                                <ToggleButton
                                    key={5}
                                    id={`fieldtype-5`}
                                    type="radio"
                                    name="fieldtype"
                                    value="pdfhead"
                                    checked={page === "pdfhead"}
                                    onClick={() => { handlePageChange("pdfhead"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Pdf Front
                                </ToggleButton>
                                <ToggleButton
                                    key={6}
                                    id={`fieldtype-6`}
                                    type="radio"
                                    name="fieldtype"
                                    value="pdftail"
                                    checked={page === "pdftail"}
                                    onClick={() => { handlePageChange("pdftail"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Pdf Tail
                                </ToggleButton>
                                <ToggleButton
                                    key={7}
                                    id={`fieldtype-7`}
                                    type="radio"
                                    name="fieldtype"
                                    value="minutespdfhead"
                                    checked={page === "minutespdfhead"}
                                    onClick={() => { handlePageChange("minutespdfhead"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Minutes Pdf Front
                                </ToggleButton>
                                <ToggleButton
                                    key={8}
                                    id={`fieldtype-8`}
                                    type="radio"
                                    name="fieldtype"
                                    value="minutespdftail"
                                    checked={page === "minutespdftail"}
                                    onClick={() => { handlePageChange("minutespdftail"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Minutes Pdf Tail
                                </ToggleButton>
                            </ButtonGroup>
                            &nbsp;
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldlang-1`}
                                    type="radio"
                                    name="fieldlang"
                                    value="en"
                                    checked={language === "en"}
                                    onClick={() => { handleLanguageChange("en"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    English
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldlang-2`}
                                    type="radio"
                                    name="fieldlang"
                                    value="cy"
                                    checked={language === "cy"}
                                    onClick={() => { handleLanguageChange("cy"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Cymraeg
                                </ToggleButton>
                            </ButtonGroup>
                            <div style={{ display: page === "pdfhead" ? '' : 'none' }}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="pdfHeadOverrideSwitch"
                                    label={data.overridePdfHead ? "Overriden" : "Use Global"}
                                    checked={data.overridePdfHead}
                                    onChange={handleOverridePdfHead}
                                />
                            </div>
                            <div style={{ display: page === "pdftail" ? '' : 'none' }}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="pdfTailOverrideSwitch"
                                    label={data.overridePdfTail ? "Overriden" : "Use Global"}
                                    checked={data.overridePdfTail}
                                    onChange={handleOverridePdfTail}
                                />
                            </div>
                            <div style={{ display: page === "minutespdfhead" ? '' : 'none' }}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="pdfHeadOverrideMinutesSwitch"
                                    label={data.overrideMinutesPdfHead ? "Overriden" : "Use Global"}
                                    checked={data.overrideMinutesPdfHead}
                                    onChange={handleOverrideMinutesPdfHead}
                                />
                            </div>
                            <div style={{ display: page === "minutespdftail" ? '' : 'none' }}>
                                <Form.Check // prettier-ignore
                                    type="switch"
                                    id="pdfTailOverrideMinutesSwitch"
                                    label={data.overrideMinutesPdfTail ? "Overriden" : "Use Global"}
                                    checked={data.overrideMinutesPdfTail}
                                    onChange={handleOverrideMinutesPdfTail}
                                />
                            </div>
                            <Container style={{ display: language === 'cy' ? "none" : "" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (en)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameEn} onChange={handleNameEnChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "contact" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.contactEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleContactChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "pdfhead" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.pdfHeadEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handlePdfHeadChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "pdftail" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.pdfTailEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handlePdfTailChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "minutespdfhead" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.minutesPdfHeadEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleMinutesPdfHeadChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "minutespdftail" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.minutesPdfTailEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleMinutesPdfTailChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                            </Container>
                            <Container style={{ display: language === 'cy' ? "" : "none" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (cy)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameCy} onChange={handleNameCyChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "contact" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.contactCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleContactChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "pdfhead" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.pdfHeadCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handlePdfHeadChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "pdftail" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.pdfTailCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handlePdfTailChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "minutespdfhead" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.minutesPdfHeadCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleMinutesPdfHeadChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "minutespdftail" ? '' : 'none' }}>
                                    <div>The following will be replaced by meeting relative text;<br /> &#123;meetingname&#125;
                                        &#123;committeename&#125;
                                        &#123;day&#125;
                                        &#123;dayname&#125;
                                        &#123;month&#125;
                                        &#123;monthname&#125;
                                        &#123;year&#125;
                                        &#123;date&#125;
                                        &#123;time&#125;
                                        &#123;time24&#125;
                                        &#123;venue&#125;
                                    </div>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.minutesPdfTailCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleMinutesPdfTailChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                            </Container>
                            <div style={{ display: page === "details" ? '' : 'none' }}>
                                <Container>
                                    <Row>
                                        <Col xs="4">
                                            Decommissioned Date
                                        </Col>
                                        <Col xs="8">
                                            <DatePicker value={data.decommissionedDate === null ? null : new Date(data.decommissionedDate)} onChange={handleDecommissionedDateChange} />
                                            &nbsp;
                                            <Button onClick={handleRemoveDecommissionedDate}>Clear</Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="4">
                                            Include in committees list
                                        </Col>
                                        <Col xs="8">
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="showInList-switch"
                                                label={data.showInList ? "Included" : "Excluded"}
                                                checked={data.showInList}
                                                onChange={handleShowInListChange}
                                            />
                                        </Col>
                                        
                                    </Row>
                                </Container>
                            </div>
                            <div style={{ display: page === "membership" ? '' : 'none' }}>
                                <CommitteeMembershipEditor value={data.membership} onChange={handleMembershipChange } />
                            </div>

                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EditCommitteePopup;