import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react";
import Button from 'react-bootstrap/Button';
import LibraryFolderPopup from "./LibraryFolderPopup";
import { useNavigate, Link } from "react-router-dom";
import SitePage from "../sitepage/SitePage";
import SecureFileLink from "../meeting/SecureFileLink";

export default function Library(props) {


    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0] || {});

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);



    useEffect(() => {
        console.log("useEffect:requestData:" + loading);
        if (!loading) {
            loadData();
        }
    }, [language, props]);

    const addRootFolder = () => {

    }
    const handleBackToParent = () => {
        let url = "/library/" + (data.parentId === undefined || data.parentId === null ? "" : data.parentId);
        setData(null)
        navigate(url);
    }
    const handleReloadData = () => {
        loadData();
    }
    function loadData() {
        setError(null);
        setData([]);
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getLibraryFolder + (props.id == null ? '' : '/' + props.id))
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.committees.sort((a, b) => {
                                    //    return a.nameEn > a.nameEn;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getLibraryFolder + (props.id == null ? '' : '/' + props.id))
                .then(function (response) {
                    console.log(response.data);
                    //response.data.committees.sort((a, b) => {
                    //    return a.nameEn > b.nameEn ? 1 : a.nameEn < b.nameEn ? -1 : 0;
                    //});
                    setData(response.data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }

    return (
        <>
            {props.id === null ? (
                <>
                    <SitePage id={"40efafa8-6b43-4e85-a87e-3b5b934823a7"} />
                    <ul>
                        {data === null ? '' : data.map((folder, index) => (
                            <li>
                                <Link to={"/library/" + folder.id}>{language === 'cy' ? folder.nameCy : folder.nameEn}</Link>
                                {isAuthenticated ? (
                                    <>
                                        &nbsp;
                                        <LibraryFolderPopup id={folder.id} onChange={handleReloadData} />
                                    </>
                                ) : ''}
                            </li>
                        ))}
                    </ul>
                    <br />
                    {isAuthenticated ? (
                        <LibraryFolderPopup onChange={handleReloadData} />
                    ) : ''}
                </>
            ) : data === null ? '' : (

                    <>
                        
                    <h1>{language === 'cy' ? data.nameCy : data.nameEn}
                            {data === null ? '' : (
                                <>&nbsp; <Button variant="success" onClick={handleBackToParent} >{t("Back")}</Button></>
                            )}
                    </h1>
                    <div dangerouslySetInnerHTML={{ __html: language === 'cy' ? data.descriptionCy : data.descriptionEn }} />
                    <br />
                    <ul>
                        {data.folders === null || data.folders === undefined ? '' : data.folders.map((folder, index) => (
                            <li>
                                <Link to={"/library/" + folder.id}>{language === 'cy' ? folder.nameCy : folder.nameEn}</Link>
                                {isAuthenticated ? (
                                    <>
                                        &nbsp;
                                        <LibraryFolderPopup id={folder.id} onChange={handleReloadData} />
                                    </>
                                ) : ''}
                            </li>
                        ))}
                    </ul>
                    <ul>
                        {data.files === null || data.files === undefined ? '' : data.files.map((file, index) => file.attachedFileEn === null && file.attachedFileCy === null ? '' : (
                            <li>
                                {language === 'cy' ? (
                                    <SecureFileLink
                                        url={authConfig.api.urls.getLibraryFileAttachment + "/" + (file.attachedFileCy === null ? file.attachedFileEn.id : file.attachedFileCy.id) + "/" + (file.attachedFileCy === null ? file.attachedFileEn.fileName : file.attachedFileCy.fileName)}
                                        filename={file.attachedFileCy === null ? file.attachedFileEn.fileName : file.attachedFileCy.fileName}>
                                        {file.nameCy !== "" ? file.nameCy : file.attachedFileCy === null ? file.attachedFileEn.fileName : file.attachedFileCy.fileName}
                                    </SecureFileLink>
                                ) : (
                                    <SecureFileLink
                                        url={authConfig.api.urls.getLibraryFileAttachment + "/" + (file.attachedFileEn === null ? file.attachedFileCy.id : file.attachedFileEn.id) + "/" + (file.attachedFileEn === null ? file.attachedFileCy.fileName : file.attachedFileEn.fileName)}
                                            filename={file.attachedFileEn === null ? file.attachedFileCy.fileName : file.attachedFileEn.fileName}>
                                            {file.nameEn !== "" ? file.nameEn : file.attachedFileEn === null ? file.attachedFileCy.fileName : file.attachedFileEn.fileName}
                                    </SecureFileLink>
                                )}

                            </li>
                        ))}
                    </ul>
                    {isAuthenticated ? (
                        <LibraryFolderPopup parentId={data.id} onChange={handleReloadData} />
                    ) : ''}
                </>
            )}


        </>
    )
}