import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import AgendaItem from './AgendaItem';
import Attendance from './Attendance';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import MeetingFiles from "./MeetingFiles";
import { Link, NavLink, useNavigate } from "react-router-dom";
import SecureFileLink from "./SecureFileLink";

function Meeting(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);

    useEffect(() => {
        console.log("useEffect:requestData:" + loading);
        if (!loading) {
            loadData();
        }
    }, [language, props]);

    const navigate = useNavigate();

    const handleEdit = () => {
        navigate("/meeting/edit/" + data.id);
    }

    function loadData() {
        setError(null);
        setData(null);
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeeting + "/" + props.MeetingId)
                                .then(function (response) {
                                    console.log(response.data);
                                    response.data.agendaItems.sort((a, b) => {
                                        return a.positionIndex - b.positionIndex;
                                    });
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getMeeting + "/" + props.MeetingId)
                .then(function (response) {
                    console.log(response.data);
                    response.data.agendaItems.sort((a, b) => {
                        return a.positionIndex - b.positionIndex;
                    });
                    setData(response.data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }
    return data === null ?
        (<></>) :
        (

            <>
                <b>
                    {authConfig.showStatusTypes.includes(data.status.name) ? t(data.status.name) + " - " : ""}
                    {language == 'cy' ? data.titleCy : data.titleEn} {getFormattedDate(data.start)}
                </b>&nbsp;
                <Attendance meeting={data} />
                {isAuthenticated ? (
                    <>
                        &nbsp;<Button onClick={handleEdit} size="sm">Edit</Button>
                    </>
                ) : ''}
                <br />

                {data.status.name.toLowerCase() === "scheduled" ? '' : (
                    <>
                        {data.agendaPdfCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingFrontsheet + "/" + data.id + "/" + (language === "cy" ? 'cy' : 'en') + "/frontsheet.pdf?dt=" + (new Date()).getTime()}
                                    filename={"frontsheet.pdf"}>{t("Agenda Frontsheet")}
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                        {data.reportsPackCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingReportspack + "/" + data.id + "/" + (language === "cy" ? 'cy' : 'en') + "/reportspack.pdf?dt=" + (new Date()).getTime()}
                                    filename={"reportspack.pdf"}>{t("Reports Pack")}
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                        {data.minutesPdfCreated && data.minutesApproved ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingMinutes + "/" + data.id + "/" + (language === "cy" ? 'cy' : 'en') + "/minutes.pdf?dt=" + (new Date()).getTime()}
                                    filename={"minutes.pdf"}>{t("Minutes")}
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                        {data.reportsPackCreated || data.agendaPdfCreated ? '' : (<MeetingFiles meeting={data} />)}


                        {data.venue !== "" ? (
                            <>
                                <div><b>Venue:</b> {data.venue}</div><br />
                            </>
                        ) : ''}
                        {data.contact !== "" ? (
                            <>
                                <div><b>Contact:</b> {data.contact}</div><br />
                            </>
                        ) : ''}
                        

                        {data.streamUrl === "" ? '' : (
                            <>
                                <div><b>Video Stream:</b> <a href={data.streamUrl} target="_blank">{data.streamUrl}</a></div>
                                <br />
                            </>
                        )}


                        {data.agendaItems ? data.agendaItems.map((agendaitem, index) => (
                            <AgendaItem key={index} item={agendaitem} />
                        )) : ''}
                    </>
                )}
                
            </>
        );
}
export default Meeting;