import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import SitePage from '../sitepage/SitePage';
import { Link, NavLink, useNavigate } from "react-router-dom";

import CommitteeSelector from '../committee/CommitteeSelector';
//import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import MeetingTitleInput from './MeetingTitleInput';

import { DateTimePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate } from '../Util.js';



function MeetingListPopup(props) {

    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const [committee, setCommittee] = useState(null);
    const [data, setData] = useState([]);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setShow(false);
        setData([]);
    }
    const handleShow = () => {
        setShow(true);
        loadData();
    }
    const handleSelectCommittee = (o) => {
        setCommittee(o);
    }
    const handleEditClick = (o) => {
        navigate("/meeting/edit/" + o.id);
    }
    function loadData() {
        setError(null)
        setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeetingList + (committee == null ? '' : "?committeeid=" + committee.id))
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getMeetingList + (committee == null ? '' : "?committeeid=" + committee.id))
                .then(function (response) {
                    //console.log(response.data);
                    //response.data.agendaItems.sort((a, b) => {
                    //    return a.positionIndex - b.positionIndex;
                    //});
                    setData(response.data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }

    return (
        <>
            <Row>
                <Col xs="8">
                    <CommitteeSelector value={committee} onChange={handleSelectCommittee} />
                </Col>
                <Col xs="4">
                    <Button variant="success" onClick={handleShow} disabled={committee == null}>
                        Open List
                    </Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Meetings for {committee == null ? '' : committee.nameEn }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data.map((meeting, index) => (
                        <Row key={index}>
                            <Col xs="4">
                                {meeting.titleEn}
                            </Col>
                            <Col xs="2">
                                {format(new Date(meeting.start), 'dd/MM/yyyy')}
                            </Col>
                            <Col xs="2">
                                {meeting.status.name}
                            </Col>
                            <Col xs="2" style={{ marginTop: "1px", marginBottom: "1px" }}>
                                { meeting.status.name === 'New' ? '' : <Button size="sm">Open</Button>}
                            </Col>
                            <Col xs="2" style={{ marginTop: "1px", marginBottom: "1px" }}>
                                <Button size="sm" onClick={() => { handleEditClick(meeting) }}>Edit</Button>
                            </Col>
                        </Row>
                    ))}
                    <Row>
                        <Col></Col>
                        <Col></Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default MeetingListPopup;