import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"
import { SignInButton } from "./components/SignInButton";
import { SignOutButton } from "./components/SignOutButton";
import authConfig from "./authConfig";
import { RequestButton } from "./components/RequestButton";
//import { CKEditor } from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import Editor from "ckeditor5-custom-classic/build/ckeditor";
import { useTranslation } from 'react-i18next';

import { useEffect, useState, useCallback } from "react";
import SiteNav from "./components/SiteNav";
import Footer from "./components/Footer";
//import './i18n';

import CalendarRoute from './routes/CalendarRoute';
import MeetingRoute from './routes/MeetingRoute';
import MeetingsRoute from './routes/MeetingsRoute';
import MeetingEditRoute from './routes/MeetingEditRoute';
import OutsideBodiesRoute from './routes/OutsideBodiesRoute';
import OutsideBodyRoute from './routes/OutsideBodyRoute';
import CommitteesRoute from './routes/CommitteesRoute';
import CommitteeRoute from './routes/CommitteeRoute';
import MembersRoute from './routes/MembersRoute';
import MemberRoute from './routes/MemberRoute';
import AdminRoute from './routes/AdminRoute';
import RootRoute from './routes/RootRoute';
import ContactRoute from './routes/ContactRoute';
import LibraryRoute from './routes/LibraryRoute';
import {
    createBrowserRouter,
    RouterProvider,
    Route,
    Routes
} from "react-router-dom";

//const router = createBrowserRouter([
//    {
//        path: "/",
//        element: <Calendar />,
//    },
//]);

function App() {
    const isAuthenticated = useIsAuthenticated();

    const { instance } = useMsal();
    const { t, i18n } = useTranslation();
    //

    // ensure active account is set
    if (
        instance.getActiveAccount() == null &&
        instance.getAllAccounts().length > 0
    ) {
        const accounts = instance.getAllAccounts()
        instance.setActiveAccount(accounts[0])
        console.log("MSAL accountInfo:", accounts[0])
    }

    const accountInfo = useAccount();




    return (
        <>
            <SiteNav></SiteNav>
            <UnauthenticatedTemplate>
                <main>
                    <Routes>
                        <Route exact path='/' element={<RootRoute />}></Route>
                        <Route exact path='/calendar' element={<CalendarRoute />}></Route>
                        <Route exact path='/meetings' element={<MeetingsRoute />}></Route>
                        <Route exact path='/meeting/:id' element={<MeetingRoute />}></Route>

                        <Route exact path='/library' element={<LibraryRoute />}></Route>
                        <Route exact path='/library/:id' element={<LibraryRoute />}></Route>
                         
                        <Route exact path='/outsidebodies' element={<OutsideBodiesRoute />}></Route>
                        <Route exact path='/outsidebody/:id' element={<OutsideBodyRoute />}></Route>
                        <Route exact path='/committees' element={<CommitteesRoute />}></Route>
                        <Route exact path='/committee/:id' element={<CommitteeRoute />}></Route>
                        <Route exact path='/members' element={<MembersRoute />}></Route>
                        <Route exact path='/member/:id' element={<MemberRoute />}></Route>
                        <Route exact path='/contact' element={<ContactRoute />}></Route>
                    </Routes>
                    <br />
                    <br />
                </main>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <main>
                    <Routes>
                        <Route exact path='/' element={<RootRoute />}></Route>
                        <Route exact path='/admin' element={<AdminRoute />}></Route>
                        <Route exact path='/calendar' element={<CalendarRoute />}></Route>
                        <Route exact path='/meetings' element={<MeetingsRoute />}></Route>
                        <Route exact path='/meeting/edit/:id' element={<MeetingEditRoute />}></Route>
                        <Route exact path='/meeting/:id' element={<MeetingRoute />}></Route>
                        <Route exact path='/library' element={<LibraryRoute />}></Route>
                        <Route exact path='/library/:id' element={<LibraryRoute />}></Route>
                        <Route exact path='/outsidebodies' element={<OutsideBodiesRoute />}></Route>
                        <Route exact path='/outsidebody/:id' element={<OutsideBodyRoute />}></Route>
                        <Route exact path='/committees' element={<CommitteesRoute />}></Route>
                        <Route exact path='/committee/:id' element={<CommitteeRoute />}></Route>
                        <Route exact path='/members' element={<MembersRoute />}></Route>
                        <Route exact path='/member/:id' element={<MemberRoute />}></Route>
                        <Route exact path='/contact' element={<ContactRoute />}></Route>
                    </Routes>
                    <br />
                    <br />
                    {/*<RequestButton*/}
                    {/*    title="MS GRAPH: Get my profileeeee"*/}
                    {/*    endpoint={authConfig.graph.urls.getMe}*/}
                    {/*    scopes={authConfig.graph.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        var user = result*/}
                    {/*        console.log("Parsed into user profile: ", user)*/}
                    {/*        return `Hello zz ${user.displayName}!`*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Get my infooo"*/}
                    {/*    endpoint={authConfig.api.urls.getMe}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Am I an adminnn ?"*/}
                    {/*    endpoint={authConfig.api.urls.getIsAdmin}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Get from admin only endpoint"*/}
                    {/*    endpoint={authConfig.api.urls.getAdminOnly}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Get from app only (daemon) endpoint (should fail)"*/}
                    {/*    endpoint={authConfig.api.urls.getDaemonOnly}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="SHAREPOINT: Do a search"*/}
                    {/*    endpoint={authConfig.sharepoint.urls.search}*/}
                    {/*    scopes={authConfig.sharepoint.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        const searchResult = result*/}
                    {/*        const titles = searchResult.PrimaryQueryResult.RelevantResults.Table.Rows.map(*/}
                    {/*            r => r.Cells.find(c => c.Key === "Title")?.Value*/}
                    {/*        ).join(", ")*/}
                    {/*        return `Search returned ${searchResult.PrimaryQueryResult.RelevantResults.RowCount} results: ${titles}`*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Call MS Graph from backend (API context)"*/}
                    {/*    endpoint={authConfig.api.urls.getGraphAppOnly}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}
                    {/*<RequestButton*/}
                    {/*    title="API: Call MS Graph from backend (User delegated context)"*/}
                    {/*    endpoint={authConfig.api.urls.getGraphDelegated}*/}
                    {/*    scopes={authConfig.api.scopes}*/}
                    {/*    printResult={result => {*/}
                    {/*        return result*/}
                    {/*    }}*/}
                    {/*/>*/}

                    {/*<CKEditor*/}
                    {/*    editor={Editor}*/}
                    {/*    data="<p>Hello from CKEditor 5!</p>"*/}
                    {/*    onReady={editor => {*/}
                    {/*        // You can store the "editor" and use when it is needed.*/}
                    {/*        console.log('Editor is ready to use!', editor);*/}
                    {/*    }}*/}
                    {/*    onChange={(event, editor) => {*/}
                    {/*        const data = editor.getData();*/}
                    {/*        console.log({ event, editor, data });*/}
                    {/*    }}*/}
                    {/*    onBlur={(event, editor) => {*/}
                    {/*        console.log('Blur.', editor);*/}
                    {/*    }}*/}
                    {/*    onFocus={(event, editor) => {*/}
                    {/*        console.log('Focus.', editor);*/}
                    {/*    }}*/}
                    {/*/>*/}

                </main>
            </AuthenticatedTemplate>

            <Footer>
                Copyright {(new Date()).getFullYear()} Brecon Beacons National Park Authority
            &nbsp;
                {isAuthenticated ? (
                    <SignOutButton />

                ) : (
                    <SignInButton />

                )}
            </Footer>
        </>
    )
}

export default App
