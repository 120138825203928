import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import Container from 'react-bootstrap/Container';
import SitePage from '../sitepage/SitePage';
import { Link, NavLink } from "react-router-dom";

function OutsideBody(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);

    useEffect(() => {
        console.log("useEffect:requestData:" + loading);
        if (!loading) {
            loadData();
        }
    }, [language, props]);

    function loadData() {
        setError(null)
        setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getOutsideBody + "/" + props.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.outsideBodies.sort((a, b) => {
                                    //    return a.nameEn > a.nameEn;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        } else {
            axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getOutsideBody + "/" + props.id)
                .then(function (response) {
                    console.log(response.data);
                    //response.data.outsideBodies.sort((a, b) => {
                    //    return a.nameEn > b.nameEn ? 1 : a.nameEn < b.nameEn ? -1 : 0;
                    //});
                    setData(response.data);
                })
                .catch(function (error) {
                    setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    setLoading(false)
                })
        }
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }
    return data === null || loading ?
        (<></>) :
        (
            <>

                {
                    language === 'cy' ?
                        (
                            <>
                                <h1>{data.nameCy}</h1>
                                <div dangerouslySetInnerHTML={{ __html: data.descriptionCy }} />
                            </>
                        )
                        :
                        (
                            <>
                                <h1>{data.nameEn}</h1>
                                <div dangerouslySetInnerHTML={{ __html: data.descriptionEn }} />

                            </>
                        )
                }
                <br />
                <b>{t("Our Representatives")}</b>

                {
                    data.resentatives ? data.resentatives.map((rep, index) => (
                        <>
                            {
                                language === 'cy' ?
                                    (
                                        <li>
                                            <Link to={"/member/" + rep.member.id}>{t(rep.title) + " " + rep.firstname + " " + rep.lastname}</Link>
                                            {rep.member.appointingAuthorityCy === "" ? '' :
                                                (
                                                    <>
                                                        &nbsp;-&nbsp;
                                                        {rep.member.appointingAuthorityCy}
                                                    </>
                                                )
                                            }
                                        </li>
                                    )
                                    :
                                    (
                                        <li>
                                            <Link to={"/member/" + rep.member.id}>{t(rep.title) + " " + rep.firstname + " " + rep.lastname}</Link>
                                            {rep.member.appointingAuthorityEn === "" ? '' :
                                                (
                                                    <>
                                                        &nbsp;-&nbsp;
                                                        {rep.member.appointingAuthorityEn}
                                                    </>
                                                )
                                            }

                                        </li>
                                    )
                            }
                        </>
                    )) : ''
                }
            </>
        );
}
export default OutsideBody;