import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';


export function isValidDate(d) {
    return d instanceof Date && !isNaN(d) && d.getFullYear() > 2000;
}
export function delocalizedIsoDate(d) {
    if (formatISO(d).indexOf('+') > 0) {
        return formatISO(d).substring(0, formatISO(d).indexOf('+'));
    } else {
        return formatISO(d);
    }
}
export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

