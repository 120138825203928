import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


function TranslationItem(props) {
    const handleChangeEn = (e) => {
        let newitem = { ...props.value };
        newitem.en = e.target.value;
        if (props.onChange) {
            props.onChange(newitem);
        }
    }
    const handleChangeCy = (e) => {
        let newitem = { ...props.value };
        newitem.cy = e.target.value;
        if (props.onChange) {
            props.onChange(newitem);
        }
    }
    return (
        <>
            { props.value.default !== '' ? (
            <Row>
                <Col>{props.value.default} </Col>
                <Col><Form.Control type="text" value={props.value.en} onChange={handleChangeEn} /></Col>
                <Col><Form.Control type="text" value={props.value.cy} onChange={handleChangeCy} /></Col>
                <Col></Col>
                </Row>
            ) : ''}
        </>
    )
}
export default TranslationItem;