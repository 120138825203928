import { InteractionType } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import authConfig from "../authConfig"
import Button from 'react-bootstrap/Button';
export const SignOutButton = () => {
    const { instance } = useMsal()

    function handleLogin() {
        if (authConfig.loginMode === InteractionType.Redirect) {
            instance.logoutRedirect().catch(e => {
                console.error(e)
            })
        } else if (authConfig.loginMode === InteractionType.Popup) {
            instance.logoutPopup().catch(e => {
                console.error(e)
            })
        }
    }

    return <Button variant="danger" size="sm" onClick={handleLogin}>Sign Out</Button>
}
