import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
//import MeetingCalendar from "../components/calendar/MeetingCalendar";
//import Meeting from "../components/meeting/Meeting";
import { Routes, Route, useParams } from 'react-router-dom';
//import OutsideBody from '../components/outsidebody/OutsideBody';
import Container from 'react-bootstrap/Container';

import Library from "../components/library/Library";
export default function OutsideBodyRoute() {

    //const [selectedMeeting, setSelectedMeeting] = useState(null);
    const onSelectOutsideBody = (eventitem) => {
        //setSelectedMeeting(eventitem.id);
    }
    const { id } = useParams();
    // 40efafa8-6b43-4e85-a87e-3b5b934823a7
    return (
        <>
            <Container>
                <Library id={id === undefined ? null : id} />
            </Container>
        </>
    );
}