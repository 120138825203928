import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, NavLink } from "react-router-dom";
import PersonSelector from "../person/PersonSelector";
import AttendanceTypeSelector from "./AttendanceTypeSelector";
import AttendanceRoleSelector from "./AttendanceRoleSelector";
import { uuidv4 } from "../Util"
function AttendanceEditor(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);
    const [show, setShow] = useState(false);

    const [newAttende, setNewAttendee] = useState({
        id: uuidv4(),
        person: null,
        attendance: "Expected",
        role: "",
        commentEn: "",
        commentCy: ""
    });

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSelectNewperson = (o) => {
        newAttende.person = o;
        setNewAttendee({ ...newAttende });
    }
    const handleSelectNewAttendance = (o) => {
        newAttende.attendance = o;
        setNewAttendee({ ...newAttende });
    }
    const handleSelectNewRole = (o) => {
        newAttende.role = o;
        setNewAttendee({ ...newAttende });
    }



    const attendees = props.meeting.attendees === undefined ?
        null :
        props.meeting.attendees.sort(
            (a, b) => {
                return (a.person.firstname + a.person.lastname) > (b.person.firstname + b.person.lastname) ? 1 : -1
            });

    const handleChangeAttendance = (o, attendee) => {
        let newattendees = [];
        for (var att of props.meeting.attendees) {
            if (att.id === attendee.id) {
                attendee.attendance = o;
                newattendees.push(attendee);
            } else {
                newattendees.push(att);
            }
        }
        if (props.onChange) {
            props.onChange(newattendees);
        }
    }
    const handleRemove = (o) => {
        console.log(o);
        let newattendees = [];
        for (var attendee of props.meeting.attendees) {
            if (attendee.id === o.id) {
                // skip
            } else {
                newattendees.push(attendee);
            }
        }
        if (props.onChange) {
            props.onChange(newattendees);
        }
    }
    const handleAddNew = () => {
        let newattendees = [...props.meeting.attendees];
        if (newattendees.filter(z => z.person.id === newAttende.person.id).length === 0) {
            newattendees.push(newAttende);
            if (props.onChange) {
                props.onChange(newattendees);
            }
        }
        setNewAttendee({
            id: uuidv4(),
            person: null,
            attendance: "Expected",
            role: "",
            commentEn: "",
            commentCy: ""
        });
    }
    return (
        <>
            <Button size="sm" variant="success" onClick={handleShow}>
                {t("Attendance Details")}
            </Button>
            <Modal show={show} onHide={handleClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Attendance Details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Add new attendee;
                    <Row>
                        <Col >
                            <PersonSelector value={newAttende.person} onChange={handleSelectNewperson} />
                        </Col>
                        <Col>
                            <AttendanceRoleSelector value={newAttende.role} onChange={handleSelectNewRole} />
                        </Col>
                        <Col>
                            <AttendanceTypeSelector value={newAttende.attendance} onChange={handleSelectNewAttendance} />
                        </Col>
                        <Col>
                            <Button
                                size="sm"
                                variant="success"
                                onClick={handleAddNew}
                                disabled={newAttende.person === null || newAttende.role === "" || newAttende.attendance === "" || props.meeting.attendees.filter(z => z.person.id === newAttende.person.id).length > 0}
                            >

                                Add
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Body>
                    {props.meeting && props.meeting.attendees !== undefined ? props.meeting.attendees.map((attendee, index) => (
                        <Row key={index}>
                            <Col>
                                {t(attendee.person.title) + " " + attendee.person.firstname + " " + attendee.person.lastname}
                            </Col>
                            <Col>{t(attendee.role)}</Col>
                            <Col>
                                <AttendanceTypeSelector value={attendee.attendance} onChange={(o) => { handleChangeAttendance(o, attendee); }} />
                            </Col>
                            <Col>
                                <Button size="sm" variant="danger" onClick={(o) => { handleRemove(attendee); }}><FaTrashAlt /></Button>
                            </Col>
                        </Row>
                    )) : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
}

export default AttendanceEditor;