import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';

import MeetingFiles from "./MeetingFiles";
import Autocomplete from "../autocomplete/Autocomplete";
import { DateTimePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate } from '../Util.js';

import AgendaItem from './AgendaItem';
import AttendanceEditor from './AttendanceEditor';
import AgendaEditor from "./AgendaEditor";
import AgendaItemEditor from "./AgendaItemEditor";
import SecureFileLink from "./SecureFileLink";
import MeetingStatusSelector from "./MeetingStatusSelector";

function MeetingEditor(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [data, setData] = useState(null);
    const [prevdata, setPrevdata] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [datachanged, setDatachanged] = useState(false);

    useEffect(() => {
        console.log("effect datachanged", loading, data);
        if (data !== prevdata) {

            if (prevdata !== null && data !== null) {
                setPrevdata(data);
                setDatachanged(true);
            }

        }
    }, [data])
    //const [date, setDate] = useState(startOfMonth(Date.now()));

    const language = useSelector((state) => state.language.value);

    useEffect(() => {
        //console.log("useEffect:requestData:" + loading);
        if (!loading) {
            loadData();
        }
    }, [language, props]);


    function loadData() {
        setError(null)
        //setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true);
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeeting + "/" + props.MeetingId)
                                .then(function (response) {
                                    console.log(response.data, loading);
                                    response.data.agendaItems.sort((a, b) => {
                                        return a.positionIndex - b.positionIndex;
                                    });
                                    setData(response.data);
                                    setPrevdata(response.data);

                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setDatachanged(false);
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    
    function saveData(callbackfunction) {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true);
                setIsSaving(true);
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getMeeting + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                    response.data.agendaItems.sort((a, b) => {
                                        return a.positionIndex - b.positionIndex;
                                    });
                                    setData(response.data);
                                    setPrevdata(response.data);
                                    setDatachanged(false);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                    setIsSaving(false);
                                    if (callbackfunction) {
                                        callbackfunction();
                                    }
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }

    }
    //
    const handleStartChange = (d) => {
        data.start = delocalizedIsoDate(d);
        setData({ ...data });
    }
    const handleTitleChangeEn = (o) => {
        console.log("handleTitleChangeEn");
        data.titleEn = o.en;
        if (o.cy) {
            data.titleCy = o.cy;
        }
        setData({ ...data });
    }
    const handleVenueChange = (o) => {
        console.log("handleVenueChange");
        data.venue = o.en;
        setData({ ...data });
    }
    const handleRestrictedChange = (e) => {
        data.isRestricted = e.target.checked;
        setData({ ...data });
    }
    const handleMinutesApprovedChange = (e) => {
        data.minutesApproved = e.target.checked;
        setData({ ...data });
    }
    const handleTitleChangeCy = (e) => {
        console.log(e.target.value)
        data.titleCy = e.target.value;
        setData({ ...data });
    }
    const handleAgendaItemsChange = (items) => {
        console.log("handleAgendaItemsChange");
        data.agendaItems = items;
        setData({ ...data });
    }
    const handleAttendanceChange = (attendees) => {
        data.attendees = attendees;
        setData({ ...data });
    }
    const handleStreamUrlChange = (e) => {
        data.streamUrl = e.target.value;
        setData({ ...data });
    }
    const handleStatusChange = (o) => {
        data.status = o;
        setData({ ...data });
    }
    const handleSave = () => {
        saveData();
    }
    //
    function getFormattedDate(date) {
        if (date) {
            return format(new Date(date), "EEEE, do LLLL, yyyy h:mm bbb", { locale: i18n.language == 'en' ? enGB : cy });
        } else {
            return "";
        }
    }
    const handleCloneAgendaAndAttendees = (o) => {
        if (o.agendaItems) {
            for (var ai of o.agendaItems) {
                data.agendaItems.push(ai);
            }
        }
        if (o.attendees) {
            for (var a of o.attendees) {
                if (data.attendees.filter(x => x.person.id === a.person.id).length === 0) {
                    data.attendees.push(a);
                }
            }
        }
        setData({ ...data });
    }
    return data === null ?
        (<></>) :
        (

            <>
                <Button variant="success"
                    onClick={handleSave}
                    disabled={isSaving || !datachanged}
                >
                    Save
                    {isSaving ? (
                        <>
                            &nbsp;
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </>
                    ) : ''}
                </Button>&nbsp;

                <b>{language == 'cy' ? data.titleCy : data.titleEn} {getFormattedDate(data.start)}</b> <AttendanceEditor meeting={data} onChange={handleAttendanceChange} /><br />
                <Row>
                    <Col xs="2">Title</Col>
                    <Col>
                        <Autocomplete
                            url={authConfig.api.urls.getMeetingTitleLookup}
                            value={data.titleEn ? data.titleEn : ''}
                            onChange={handleTitleChangeEn}

                        />
                    </Col>
                    <Col>
                        <input className="form-control" type="text" value={data.titleCy ? data.titleCy : ''} onChange={handleTitleChangeCy} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        Date/Time
                    </Col>
                    <Col>
                        <DateTimePicker value={new Date(data.start)} onChange={handleStartChange} />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        Venue
                    </Col>
                    <Col>
                        <Autocomplete
                            url={authConfig.api.urls.getMeetingVenueLookup}
                            value={data.venue ? data.venue : ''}
                            onChange={handleVenueChange}
                            minQueryLength={1}
                        />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">Stream Url</Col>
                    <Col>
                        <input className="form-control" type="text" value={data.streamUrl} onChange={handleStreamUrlChange} />
                    </Col>
                    <Col>

                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        Restricted from public
                    </Col>
                    <Col>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="restricted-switch"
                            label={data.isRestricted ? "Restricted" : "Not Restricted"}
                            checked={data.isRestricted}
                            onChange={handleRestrictedChange}
                        />
                    </Col>
                    <Col>
                    </Col>
                </Row>

                <Row>
                    <Col xs="2">
                        Minutes approved (publicly available)
                    </Col>
                    <Col>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="minutesapproved-switch"
                            label={data.minutesApproved ? "Approved" : "Not Approved"}
                            checked={data.minutesApproved}
                            onChange={handleMinutesApprovedChange}
                        />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        Meeting Status
                    </Col>
                    <Col>
                        <MeetingStatusSelector value={data.status} onChange={handleStatusChange} />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs="2">
                        Restricted Pdfs
                    </Col>
                    <Col>
                        {data.agendaPdfCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingFrontsheet + "/" + data.id + "/en/frontsheet.pdf?dt=" + (new Date()).getTime()}
                                    filename={"frontsheet-restricted.pdf"}>Frontsheet (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingFrontsheet + "/" + data.id + "/cy/frontsheet.pdf?dt=" + (new Date()).getTime()}
                                    filename={"frontsheet-restricted.pdf"}>Frontsheet (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}

                        {data.reportsPackCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingReportspack + "/" + data.id + "/en/reportspack.pdf?dt=" + (new Date()).getTime()}
                                    filename={"reportspack-restricted.pdf"}>Reports Pack (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingReportspack + "/" + data.id + "/cy/reportspack.pdf?dt=" + (new Date()).getTime()}
                                    filename={"reportspack-restricted.pdf"}>Reports Pack (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                        {data.minutesPdfCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingMinutes + "/" + data.id + "/en/minutes.pdf?dt=" + (new Date()).getTime()}
                                    filename={"minutes-restricted.pdf"}>Minutes (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingMinutes + "/" + data.id + "/cy/minutes.pdf?dt=" + (new Date()).getTime()}
                                    filename={"minutes-restricted.pdf"}>Minutes (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs="2">
                        Public Pdfs
                    </Col>
                    <Col>
                        {data.agendaPdfCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingFrontsheetPublic + "/" + data.id + "/en/frontsheet.pdf?dt=" + (new Date()).getTime()}
                                    filename={"frontsheet-public.pdf"}>Frontsheet (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingFrontsheetPublic + "/" + data.id + "/cy/frontsheet.pdf?dt=" + (new Date()).getTime()}
                                    filename={"frontsheet-public.pdf"}>Frontsheet (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}

                        {data.reportsPackCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingReportspackPublic + "/" + data.id + "/en/reportspack.pdf?dt=" + (new Date()).getTime()}
                                    filename={"reportspack-public.pdf"}>Reports Pack (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingReportspackPublic + "/" + data.id + "/cy/reportspack.pdf?dt=" + (new Date()).getTime()}
                                    filename={"reportspack-public.pdf"}>Reports Pack (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                        {data.minutesPdfCreated ? (
                            <>
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingMinutesPublic + "/" + data.id + "/en/minutes.pdf?dt=" + (new Date()).getTime()}
                                    filename={"minutes-public.pdf"}>Minutes (en)
                                </SecureFileLink>
                                &nbsp;
                                <SecureFileLink
                                    type="button"
                                    url={authConfig.api.urls.getMeetingMinutesPublic + "/" + data.id + "/cy/minutes.pdf?dt=" + (new Date()).getTime()}
                                    filename={"minutes-public.pdf"}>Minutes (cy)
                                </SecureFileLink>
                                &nbsp;
                            </>
                        ) : ''}
                    </Col>
                </Row>
                {data.reportsPackCreated || data.agendaPdfCreated ? '' : (<MeetingFiles meeting={data} edit={true} />)}
                <br />
                <AgendaEditor
                    data={data.agendaItems}
                    onChange={handleAgendaItemsChange}
                    committee={data.committee}
                    onCloneAgendaAndAttendees={handleCloneAgendaAndAttendees}
                />

            </>
        );
}
export default MeetingEditor;