import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import SitePage from '../sitepage/SitePage';
import { Link, NavLink, useNavigate } from "react-router-dom";

import CommitteeSelector from '../committee/CommitteeSelector';
//import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';


import { DateTimePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate } from '../Util.js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";


function EditSitePagePopup(props) {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (props.data) {
            setData(props.data);
        }
    })

    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getSitePage + "/" + props.id)
                                .then(function (response) {
                                    //console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getSitePage + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }

    const handleClose = () => {
        setShow(false);
        setData(null);
    }
    const handleShow = () => {
        setShow(true);
        //loadData();
    }
    const handleSave = () => {
        saveData();
        if (props.onChange) {
            props.onChange(data);
        }
        setShow(false);
    }
    const handleChange = (html, lang) => {
        if (lang === "en") {
            data.items[0].htmlEn = html;
            setData({ ...data });
        } else if (lang === "cy") {
            data.items[0].htmlCy = html;
            setData({ ...data });
        }
    }
    const handleTitleChangeEn = (e) => {
        data.titleEn = e.target.value;
        setData({ ...data });
    }
    const handleTitleChangeCy = (e) => {
        data.titleCy = e.target.value;
        setData({ ...data });
    }
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }

    return (
        <>
            <Button variant="success" size="sm" onClick={handleShow} >
                Edit
            </Button>

            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Page</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldlang-1`}
                                    type="radio"
                                    name="fieldlang"
                                    value="en"
                                    checked={language === "en"}
                                    onClick={() => { handleLanguageChange("en"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    English
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldlang-2`}
                                    type="radio"
                                    name="fieldlang"
                                    value="cy"
                                    checked={language === "cy"}
                                    onClick={() => { handleLanguageChange("cy"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Cymraeg
                                </ToggleButton>
                            </ButtonGroup>
                            <div style={{ display: language == "en" ? '' : 'none' }}>
                                <Row>
                                    <Col xl="4">Title (en)</Col>
                                    <Col xl="8">
                                        <Form.Control value={data.titleEn} onChange={handleTitleChangeEn} />
                                    </Col> 
                                </Row>
                                <CKEditor
                                    editor={Editor}
                                    data={data.items[0].htmlEn}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const html = editor.getData();
                                        handleChange(html, 'en');
                                        //console.log({ event, editor, data });
                                    }}
                                    onBlur={(event, editor) => {
                                        //console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log('Focus.', editor);
                                    }}
                                />
                            </div>
                            <div style={{ display: language == "cy" ? '' : 'none' }}>
                                <Row>
                                    <Col xl="4">Title (cy)</Col>
                                    <Col xl="8">
                                        <Form.Control value={data.titleCy} onChange={handleTitleChangeCy} />
                                    </Col>
                                </Row>
                                <CKEditor
                                    editor={Editor}
                                    data={data.items[0].htmlCy}
                                    onReady={editor => {
                                        // You can store the "editor" and use when it is needed.
                                        //console.log('Editor is ready to use!', editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const html = editor.getData();
                                        handleChange(html, 'cy');
                                        //console.log({ event, editor, data });
                                    }}
                                    onBlur={(event, editor) => {
                                        //console.log('Blur.', editor);
                                    }}
                                    onFocus={(event, editor) => {
                                        //console.log('Focus.', editor);
                                    }}
                                />
                            </div>

                            <Row>
                                <Col></Col>
                                <Col></Col>
                            </Row>
                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default EditSitePagePopup;