import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import authConfig from "../../authConfig";
import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

function YearSelector(props) {
    const language = useSelector((state) => state.language.value);
    const years = [];
    const date = props.date ? props.date : new Date();
    const selectedyear = props.date.getFullYear();

    for (var i = 2005; i <= (new Date()).getFullYear() + 2; i++) {
        years.push(i);
    }
    const handleChange = (e) => {
        const selectedyear = e.target.value;
        let newdate = props.date.setYear(selectedyear);
        if (props.onChange) {
            props.onChange(newdate);
        }
    }

    return (
        <>
            <Form.Select value={selectedyear} onChange={handleChange}>
                {
                    years.map((year) => (
                        <option key={year} value={year} >
                            {year}
                        </option>
                    ))
                }
            </Form.Select>
        </>);
}
export default YearSelector;