import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import authConfig from "../../authConfig";
import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';
import { useSelector, useDispatch } from 'react-redux';
import Form from 'react-bootstrap/Form';

function MonthSelector(props) {
    const language = useSelector((state) => state.language.value);
    const months = [];
    const date = props.date ? props.date : new Date();
    const selectedmonth = props.date.getMonth();
    for (var i = 0; i < 12; i++) {
        let d = startOfMonth(new Date());
        d.setMonth(i);
        months.push({
            value: i,
            name: format(d, 'LLLL', {
                locale: language == 'en' ? enGB : cy
            }
            )
        });

    }
    //requestData();
    useEffect(() => {
        

    }, [date, language]);

    const handleChange = (e) => {
        const selectedmonth = e.target.value;
        let newdate = props.date.setMonth(selectedmonth);
        if (props.onChange) {
            props.onChange(newdate);
        }
    }

    return (
        <>
            <Form.Select value={selectedmonth} onChange={handleChange}>
                {
                    months.map((month) => (
                        <option key={ month.value  } value={month.value} >
                            {month.name}
                        </option>
                    ))
                    }
            </Form.Select>
        </>);
}
export default MonthSelector;