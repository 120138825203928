import React from 'react';

import AsyncSelect from 'react-select/async';
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import { AsyncPaginate } from 'react-select-async-paginate';



import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

function PersonSelector(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})



    const language = useSelector((state) => state.language.value);


    async function getPeopleAsync(search, page, offset) {
        let returnvalue = {};
        let authresponse = null;


        if (isAuthenticated) {
            if (account /*&& loading == false*/) {
                //setLoading(true)
                await instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        authresponse = response;
                    })
                    .catch(error => {
                        //setError("Error getting token: " + error.message)
                        console.error(error)
                        //setLoading(false)
                    });
                if (authresponse) {
                    //console.log("Got access token:", authresponse);
                    await axios
                        .create({
                            headers: { Authorization: `Bearer ${authresponse.accessToken}` }
                        })
                        .get(authConfig.api.urls.getPersonSelectList + '?search=' + search + '&page=' + page + '&offset=0' + (props.membersonly ? '&membersonly=true' : ''))
                        .then(function (response) {
                            //console.log(response.data);
                            /*response.data.agendaItems.sort((a, b) => {
                                return a.positionIndex - b.positionIndex;
                            });
                            setData(response.data);*/
                            returnvalue = response.data;
                            return response.data;
                        })
                        .catch(function (error) {
                            //setError("Error fetching data: " + error.message)
                            console.error(error)
                        })
                        .then(function () {
                            // always executed
                            //setLoading(false)
                        })
                } else {
                    //setError("Empty token??")
                    console.error("no token acquired")
                }
            }
        } else {
            await axios
                .create({
                    /*headers: { Authorization: `Bearer ${response.accessToken}` }*/
                })
                .get(authConfig.api.urls.getPersonSelectList + '?search=' + search + '&page=' + page + '&offset=0' + (props.membersonly ? '&membersonly=true' : ''))
                .then(function (response) {
                    //console.log(response.data);
                    /*response.data.agendaItems.sort((a, b) => {
                        return a.positionIndex - b.positionIndex;
                    });*/
                    returnvalue = response.data;
                    return response.data;
                })
                .catch(function (error) {
                    //setError("Error fetching data: " + error.message)
                    console.error(error)
                })
                .then(function () {
                    // always executed
                    //setLoading(false)
                });
        }

        return returnvalue;
    }


    const handleChange = (o) => {
        if (props.onChange) {
            props.onChange(o == null ? null : o.obj);
        }
    }

    return (
        <>
            <AsyncPaginate
                value={props.value ? { label: props.value.title + " " + props.value.firstname + " " + props.value.lastname + (props.value.member ? " (Member)" : ''), value: props.value.id } : {}}
                loadOptions={
                    async (search, loadedOptions, { page }) => {
                        const responseJSON = await getPeopleAsync(search, page, 0);
                        return {
                            options: responseJSON.result,
                            hasMore: responseJSON.hasmore,
                            additional: {
                                page: page + 1,
                            },
                        };
                    }}
                onChange={handleChange}
                additional={
                    { page: 1, }
                }
                readOnly={false}
                isClearable
            />
        </>
    )

}

export default PersonSelector;