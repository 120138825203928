
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react";
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';
import authConfig from "../../authConfig";
import { uuidv4 } from '../Util.js';

export default function LibraryFileEdit(props) {

    //const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const handleNameEnChange = (e) => {
        let newvalue = { ...props.value };
        newvalue.nameEn = e.target.value;
        if (props.onChange) {
            props.onChange(newvalue);
        }
    }
    const handleNameCyChange = (e) => {
        let newvalue = { ...props.value };
        newvalue.nameCy = e.target.value;
        if (props.onChange) {
            props.onChange(newvalue);
        }
    }
    const handleRemove = () => {
        if (props.onRemove) {
            props.onRemove(props.value);
        }
    }

    async function handleUpload(t, e) {
        if (e.target.value) {
            const formdata = new FormData();
            formdata.append('file', e.target.files[0]);
            formdata.append('id', uuidv4());

            if (e.target.files[0].type === ("application/pdf")) {
                console.log(e.target.files[0]);
                let authresponse = null;
                //setLoading(true);
                if (isAuthenticated) {
                    if (account) {
                        //setLoading(true)
                        await instance
                            .acquireTokenSilent({
                                scopes: authConfig.api.scopes,
                                account: account
                            })
                            .then(response => {
                                authresponse = response;
                            })
                            .catch(error => {
                                //setError("Error getting token: " + error.message)
                                //console.error(error)
                                //setLoading(false);
                            });
                    }
                }
                (async () => {
                    let headers = new Headers();
                    if (authresponse) {
                        headers.append('Authorization', 'Bearer ' + authresponse.accessToken);
                    }
                    const response = await fetch(authConfig.api.urls.getLibraryFileUploadFile, {
                        method: "post",
                        headers,/*headers: { 'Content-Type': 'multipart/form-data' },*/
                        body: formdata
                    });
                    if (response.ok) {
                        const jsonData = await response.json();
                        addFile(t, jsonData);
                    }


                })();
            }

            e.target.value = null;
        }
    }
    const addFile = (t, attachmentjson) => {
        let newdata = { ...props.value };
        if (t === 'en') {
            newdata.attachedFileEn = attachmentjson;
        }
        if (t === 'cy') {
            newdata.attachedFileCy = attachmentjson;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }

    return (
        <>
            <Row>
                <Col xs="2">Name (En)</Col>
                <Col><Form.Control type="text" value={props.value.nameEn} onChange={handleNameEnChange} /></Col>
                <Col xs="2">Name(Cy)</Col>
                <Col><Form.Control type="text" value={props.value.nameCy} onChange={handleNameCyChange} /></Col>
                <Col xs="1"><Button size="sm" variant="danger" onClick={handleRemove}><FaTrashAlt /> </Button></Col>
            </Row>
            <Row>
                <Col xs="2">File (En)</Col>
                <Col>
                    {props.value.attachedFileEn === null ? (
                        <Form.Control type="file" accept=".pdf" onChange={(e) => { handleUpload('en', e); }} />
                    ) : (
                    <>
                        { props.value.attachedFileEn.name }
                                </>
                    )}

                </Col>
                <Col xs="2">File (Cy)</Col>
                <Col>
                    {props.value.attachedFileCy === null ? (
                        <Form.Control type="file" accept=".pdf" onChange={(e) => { handleUpload('cy', e); }} />
                    ) : (
                        <>
                            {props.value.attachedFileCy.name}
                        </>
                    )}
                </Col>
                <Col xs="1"><Button size="sm" variant="danger" onClick={handleRemove}><FaTrashAlt /> </Button></Col>
            </Row>
            <hr />
        </>
    )
}