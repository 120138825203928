import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link, NavLink } from "react-router-dom";

function Attendance(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const attendees = props.meeting.attendees === undefined ?
        null :
        props.meeting.attendees.sort(
            (a, b) => {
                return (a.person.firstname + a.person.lastname) > (b.person.firstname + b.person.lastname) ? 1 : -1
            });

    return (
        <>
            <Button size="sm" variant="success" onClick={handleShow}>
                {t("Attendance Details")}
            </Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Attendance Details")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.meeting && props.meeting.attendees !== undefined ? props.meeting.attendees.map((attendee, index) => (
                        <Row key={index}>
                            <Col>
                                {attendee.person.member === null ?
                                    (
                                        <>{t(attendee.person.title) + " " + attendee.person.firstname + " " + attendee.person.lastname}</>
                                    ) : (
                                        <Link to={"/member/" + attendee.person.member.id}>
                                            {t(attendee.person.title) + " " + attendee.person.firstname + " " + attendee.person.lastname}
                                        </Link>
                                    ) }
                                
                            </Col>
                            <Col>{t(attendee.role)}</Col>
                            <Col>{t(attendee.attendance)}</Col>
                        </Row>
                    )) : ''}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>);
}

export default Attendance;