import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AgendaItemFiles from './AgendaItemFiles'

function AgendaItem(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);

    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }

    return (<>
        <Row>
            <Col xs={2} md={1}>{props.item.tagEn}</Col>
            <Col xs={8} md={11}>
                <>
                    <div>
                        <b>{language === 'cy' ? props.item.nameCy : props.item.nameEn}  </b>
                    </div>
                    <AgendaItemFiles agendaitem={props.item} />
                    <div dangerouslySetInnerHTML={{ __html: language === 'cy' ? props.item.descriptionCy : props.item.descriptionEn }} />
                    {
                        language === 'cy' ? (
                            <>
                                {
                                    props.item.decisionCy ? (<>
                                        <div dangerouslySetInnerHTML={{ __html: props.item.decisionEn }} />
                                    </>) : (<></>)
                                }
                            </>) : (
                            <>
                                {
                                    props.item.decisionEn ? (<>
                                        <div dangerouslySetInnerHTML={{ __html: props.item.decisionEn }} />
                                    </>) : (<></>)
                                }
                            </>)
                    }


                    {
                        language === 'cy' ? (<>
                            {
                                props.item.minutesCy ? (
                                    <>
                                        <div><b>{t("Minutes")}</b></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.item.minutesCy }} />
                                    </>

                                ) : (<></>)
                            }</>) :
                            (<>
                                {
                                    props.item.minutesEn ? (
                                        <>
                                            <div><b>Minutes</b></div>
                                            <div dangerouslySetInnerHTML={{ __html: props.item.minutesEn }} />
                                        </>
                                    ) : (<></>)
                                }</>)
                    }
                </>
            </Col>
        </Row>
    </>)
}
export default AgendaItem;