import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
//import MeetingCalendar from "../components/calendar/MeetingCalendar";
//import Meeting from "../components/meeting/Meeting";
import { Routes, Route, useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Committee from "../components/committee/Committee";
export default function CommitteeRoute() {

    //const [selectedMeeting, setSelectedMeeting] = useState(null);
    const onSelectCommittee = (eventitem) => {
        //setSelectedMeeting(eventitem.id);
    }
    const { id } = useParams();

    return (
        <>
            <Container>
                {id != null ?
                    (<div>
                        <Committee id={id }></Committee>
                    </div>)
                    :
                    (<div>No Outside Body Found
                        {id}</div>)
                }
            </Container>
        </>
    );
}