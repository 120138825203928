import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MeetingCalendar from "../components/calendar/MeetingCalendar";
import Meeting from "../components/meeting/Meeting";
import { useNavigate } from "react-router-dom";
import MembersPage from "../components/member/MemberPage"
import Container from 'react-bootstrap/Container';
export default function MembersRoute() {

    const [selectedMember, setSelectedMember] = useState(null);
    const navigate = useNavigate();

    const onSelectMember = (eventitem) => {
        //console.log(eventitem.id);
        //setSelectedMeeting(eventitem.id);
        navigate("/member/" + eventitem.id);
    }


    return (
        <>
            <Container>
                <MembersPage></MembersPage>
            </Container>
        </>
    );
}