import { useEffect, useState, useCallback } from "react";
import { DatePicker } from '@mui/x-date-pickers';
import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import PersonSelector from "../person/PersonSelector.js";
import MembershipRoleSelector from "./MembershipRoleSelector.js";

function CommitteeMembershipEditor(props) {
    
    const [person, setPerson] = useState(null);
    const [role, setRole] = useState("");


    const handleNewPersonChange = (o) => {
        setPerson(o);
    }

    const handleAdd = () => {
        
            let newmemberships = [...props.value,
                {
                    id: uuidv4,
                    person: person,
                    role: ""
                }
            ];
            if (props.onChange) {
                props.onChange(newmemberships);
            }
    }
    const handleRemove = (o) => {
        let newmemberships = [];
        for (var membership of props.value) {
            if (membership.id === o.id) {
                // skip
            } else {
                newmemberships.push(membership);
            }
        }
        if (props.onChange) {
            props.onChange(newmemberships);
        }
    }
    const handleRoleChange = (v, o) => {
        let newmemberships = [];
        try {
            for (var membership of props.value) {
                if (membership.id === o.id) {
                    let newmembership = { ...membership }
                    newmembership.role = v;
                    newmemberships.push(newmembership);
                } else {
                    newmemberships.push(membership);
                }
            }
            if (props.onChange) {
                props.onChange(newmemberships);
            }
        } catch {

        }
    }
   
    const handleToChange = (v, t) => {
        let newmemberships = [];
        let newdate = null;
        try {
            for (var membership of props.value) {
                if (membership.id === t.id) {
                    let newmembership = { ...membership }
                    newmembership.person = v;
                    newmemberships.push(newmembership);
                } else {
                    newmemberships.push(membership);
                }
            }
            if (props.onChange) {
                props.onChange(newmemberships);
            }
        } catch { }
    }
    return props.value ? (
        <>
            <Row>
                <Col xs="2">
                    Add new membership
                </Col>
                <Col xs="4">
                    <PersonSelector value={person} onChange={handleNewPersonChange} membersonly={true} />
                </Col>
                <Col xs="4">
                    <Button
                        onClick={handleAdd}
                        disabled={false}
                    >
                        Add
                    </Button>
                </Col>
                <Col>
                   
                </Col>
            </Row>
            {props.value.length > 0 ? (
                <b>Members</b>
            ) : ''}
            {props.value.sort((a, b) => {
                return (a.person.firstname + " " + a.person.lastname) > (b.person.firstname + " " + b.person.lastname) ? 1 : -1
            }).map((membership, index) => (
                <Row key={index}>
                    <Col xs="4">
                        {
                            membership.person.title + " " +
                            membership.person.firstname + " " +
                            membership.person.lastname
                        }
                    </Col>
                    <Col xs="4">
                        <MembershipRoleSelector
                            value={membership.role}
                            onChange={(e) => { handleRoleChange(e, membership); }} />
                    </Col>
                    <Col xs="2"><Button variant="danger" size="sm" onClick={() => { handleRemove(membership); }}><FaTrashAlt /></Button></Col>
                </Row>
            ))}
        </>
    ) : (<></>)

}

export default CommitteeMembershipEditor;