import { InteractionType } from "@azure/msal-browser"
const isDevMode = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? true : false;
// MSAL Configuration: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
const msalConfig = {
    auth: {
        clientId: "adafe508-5cf9-4e7c-93a4-1076ee10a703", // [SPA AZURE APP CLIENT ID GUID]
        authority:
            "https://login.microsoftonline.com/05d91053-9514-4285-9666-ce29917a689c/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: isDevMode ? "http://localhost:3000" : "https://governance.beacons-npa.gov.uk"
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
}

const graphConfig = {
    scopes: ["User.Read"],
    urls: {
        getMe: "https://graph.microsoft.com/v1.0/me"
    }
}
const apiUrl = isDevMode ? "https://localhost:7114/" : "https://governanceapi.beacons-npa.gov.uk/";
const apiConfig = {
    isDevMode: isDevMode,
    scopes: [
        "api://d0dce54f-91d3-4133-885e-af48b97d5cf3/Governance.Client" // [API AZURE APP CLIENT ID]
    ],
    urls: {
        getMe: apiUrl + "api/Me",
        getIsAdmin: apiUrl + "api/IsAdmin",
        getAdminOnly: apiUrl + "api/AdminOnly",
        getDaemonOnly: apiUrl + "api/DaemonOnly",
        getGraphAppOnly: apiUrl + "api/GraphAppOnly",
        getGraphDelegated: apiUrl + "api/GraphDelegated",

        translation: apiUrl + "api/translation",
        getTranslation: apiUrl + "api/translation",
        translationMissing: apiUrl + "api/translationMissing",
        getTranslationList: apiUrl + "api/translation/list",

        getMeetingCalendar: apiUrl + "api/meeting/calendarevents",
        getMeeting: apiUrl + "api/meeting",
        getMeetingFile: apiUrl + "api/meeting/file",
        getMeetingTitleLookup: apiUrl + "api/meeting/titlelookup",
        getMeetingVenueLookup: apiUrl + "api/meeting/venuelookup",
        getMeetingNew: apiUrl + "api/meeting/new",
        getMeetingList: apiUrl + "api/meeting/list",
        getMeetingSelectList: apiUrl + "api/meeting/selectlist",
        getMeetingStatusSelectList: apiUrl + "api/meetingstatus/selectlist",

        getMeetingMinutes: apiUrl + "api/meeting/minutes",
        getMeetingFrontsheet: apiUrl + "api/meeting/frontsheet",
        getMeetingReportspack: apiUrl + "api/meeting/reportspack",
        getMeetingMinutesPublic: apiUrl + "api/meeting/minutespublic",
        getMeetingFrontsheetPublic: apiUrl + "api/meeting/frontsheetpublic",
        getMeetingReportspackPublic: apiUrl + "api/meeting/reportspackpublic",


        getAgendaItemNew: apiUrl + "api/agendaitem/new",
        getAgendaItemName: apiUrl + "api/agendaitem/namelookup",
        getAgendaItemUploadFile: apiUrl + "api/agendaitem/uploadfile",

        getAttendanceSelectList: apiUrl + "api/meeting/attendance/selectlist",
        getRoleSelectList: apiUrl + "api/meeting/role/selectlist",

        getOutsideBodiesPage: apiUrl + "api/outsidebody/page",
        getOutsideBody: apiUrl + "api/outsidebody",
        getOutsideBodySelectList: apiUrl +"api/outsidebody/selectlist",

        getMembersPage: apiUrl + "api/member/page",
        getMemberSummary: apiUrl + "api/member/summary",
        getMemberPhoto: apiUrl + "api/member/photo",
        getMemberPublicFile: apiUrl + "api/member/publicfile",
        getMemberUploadFile: apiUrl + "api/member/uploadfile",
        getMemberAppointingAuthorityLookup: apiUrl + "api/member/appointingauthoritylookup",
        getMemberTitleLookup: apiUrl + "api/member/titlelookup",

        getCommitteesPage: apiUrl + "api/committee/page",
        getCommittee: apiUrl + "api/committee",
        getCommitteeSelectList: apiUrl + "api/committee/selectlist",
        getCommitteeMembershipRoleSelectList: apiUrl +"api/committee/role/selectlist",


        getPersonSelectList: apiUrl + "api/person/selectlist",
        getPerson: apiUrl + "api/person",
        getPersonTitleSelectList: apiUrl + "api/person/title/selectlist",
        getPersonTypeSelectList: apiUrl + "api/person/type/selectlist",
        getConfigItem: apiUrl + "api/configitem",
        getConfigItemByName: apiUrl + "api/configitem/byname",

        getSitePage: apiUrl + "api/sitepage",

        getLibraryFolder: apiUrl + "api/libraryfolder",

        getLibraryFile: apiUrl + "api/libraryfile",
        getLibraryFileAttachment: apiUrl + "api/libraryfile/file",
        getLibraryFileUploadFile: apiUrl + "api/libraryfile/uploadfile"
    }
}

/*const sharepointConfig = {
    scopes: ["https://beaconsnpagovuk.sharepoint.com/Sites.Search.All"],
    urls: {
        search:
            "https://beaconsnpagovuk.sharepoint.com/_api/search/query?querytext=%27sharepoint%27&rowLimit=3"
    }
}*/

const loginMode = InteractionType.Redirect // Redirect or Popup

const authConfig = {
    msal: msalConfig,
    graph: graphConfig,
    //sharepoint: sharepointConfig,
    api: apiConfig,
    loginMode: loginMode,
    showStatusTypes: ["Postponed","Cancelled"]
}
export default authConfig
