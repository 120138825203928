import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { DatePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";
import OutsideBodySelector from "./OutsideBodySelector";
import OutsideBodyRepresentativesEditor from "./OutsideBodyRepresentativesEditor";
//import TitleSelector from "./TitleSelector";
//import PersonTypeSelector from "./PersonTypeSelector";
//import Autocomplete from "../autocomplete/Autocomplete";
//import MemberTermsEditor from "../member/MemberTermsEditor";
//import CommitteeMembershipEditor from "./CommitteeMembershipEditor";

function EditOutsideBodyPopup(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    //const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [outsidebody, setOutsidebody] = useState(null);
    const [page, setPage] = useState("details");

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getOutsideBody + "/" + outsidebody.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getOutsideBody + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    function addData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .post(authConfig.api.urls.getOutsideBody, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }
    const handleClose = () => {
        setShow(false);
        setData(null);
    }
    const handleShow = () => {
        setShow(true);
        if (props.addnew) {
            setData({
                id: uuidv4(),
                "nameEn": "",
                "nameCy": "",
                "descriptionEn": "",
                "descriptionCy": "",
                "contactEn": "",
                "contactCy": "",
                "resentatives": []
            });
        } else {
            loadData();
        }

    }
    const handleSave = () => {
        if (props.addnew) {
            addData();
            handleClose();
        } else {
            saveData();
        }

    }

    const handleSelectOutsideBody = (o) => {
        setOutsidebody(o);
    }



    const handlePageChange = (p) => {
        setPage(p);
    }
    const handleRepresentativesChange = (o) => {
        data.resentatives = o;
        setData({ ...data });
    }
    /*
     * 
     * */

    const handleDescriptionChange = (html, lang) => {
        if (lang === "en") { data.descriptionEn = html; }
        if (lang === "cy") { data.descriptionCy = html; }
        setData({ ...data });
    }
    const handleContactChange = (html, lang) => {
        if (lang === "en") { data.contactEn = html; }
        if (lang === "cy") { data.contactCy = html; }
        setData({ ...data });
    }
    const handleNameEnChange = (e) => {
        data.nameEn = e.target.value;
        setData({ ...data });
    }
    const handleNameCyChange = (e) => {
        data.nameCy = e.target.value;
        setData({ ...data });
    }
    const handleDecommissionedDateChange = (v) => {
        console.log("decomdatechange:", v);
        data.decommissionedDate = delocalizedIsoDate(v);
        data.isCurrent = false;
        setData({ ...data });
    }
    const handleRemoveDecommissionedDate = () => {
        data.decommissionedDate = null;
        data.isCurrent = true;
        setData({ ...data });
    }
    /*
     * 
     * */
    return (
        <>
            {props.addnew ?
                (
                    <Button variant="success" onClick={handleShow}>
                        Add Outside Body
                    </Button>
                ) :
                (
                    <Row>
                        <Col xs="10">
                            <OutsideBodySelector value={outsidebody} onChange={handleSelectOutsideBody} />
                        </Col>
                        <Col xs="2">
                            <Button variant="success" onClick={handleShow} disabled={false}>
                                Edit
                            </Button>
                        </Col>
                    </Row >)
            }


            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.addnew ? "Add Outside Body" : "Edit Outside Body"} - {data == null ? '' : data.nameEn}</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldtype-1`}
                                    type="radio"
                                    name="radio"
                                    value="details"
                                    checked={page === "details"}
                                    onClick={() => { handlePageChange("details"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Outside Body Details
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldtype-2`}
                                    type="radio"
                                    name="fieldtype"
                                    value="description"
                                    checked={page === "description"}
                                    onClick={() => { handlePageChange("description"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Description
                                </ToggleButton>
                                <ToggleButton
                                    key={3}
                                    id={`fieldtype-3`}
                                    type="radio"
                                    name="fieldtype"
                                    value="contact"
                                    checked={page === "contact"}
                                    onClick={() => { handlePageChange("contact"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Contact
                                </ToggleButton>
                                <ToggleButton
                                    key={4}
                                    id={`fieldtype-4`}
                                    type="radio"
                                    name="fieldtype"
                                    value="representatives"
                                    checked={page === "representatives"}
                                    onClick={() => { handlePageChange("representatives"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Representatives
                                </ToggleButton>
                            </ButtonGroup>
                            &nbsp;
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldlang-1`}
                                    type="radio"
                                    name="fieldlang"
                                    value="en"
                                    checked={language === "en"}
                                    onClick={() => { handleLanguageChange("en"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    English
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldlang-2`}
                                    type="radio"
                                    name="fieldlang"
                                    value="cy"
                                    checked={language === "cy"}
                                    onClick={() => { handleLanguageChange("cy"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Cymraeg
                                </ToggleButton>
                            </ButtonGroup>
                            <Container style={{ display: language === 'cy' ? "none" : "" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (en)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameEn} onChange={handleNameEnChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "contact" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.contactEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleContactChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                            </Container>
                            <Container style={{ display: language === 'cy' ? "" : "none" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (cy)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameCy} onChange={handleNameCyChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "contact" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.contactCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleContactChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                            </Container>
                            <div style={{ display: page === "details" ? '' : 'none' }}>
                                
                            </div>
                            <div style={{ display: page === "representatives" ? '' : 'none' }}>
                                <OutsideBodyRepresentativesEditor value={data.resentatives} onChange={handleRepresentativesChange} />
                            </div>

                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EditOutsideBodyPopup;