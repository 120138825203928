import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import TranslationItem from './TranslationItem';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

function EditTranslationsPopup(props) {

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})


    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);
    const [show, setShow] = useState(false);

    function loadData() {
        setError(null)
        //setData([])
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true);
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getTranslationList)
                                .then(function (response) {
                                    console.log(response.data, loading);
                                    response.data.sort((a, b) => {
                                        return a.default > b.default ? 1 : -1;
                                    });
                                    setData(response.data);

                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveItem(item) {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getTranslation + "/" + item.id, item)
                                .then(function (response) {
                                    //console.log(response);

                                    //setData(response.data);
                                    //setPrevdata(response.data);
                                    //setDatachanged(false);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }

    const handleClose = () => {
        setShow(false);
        setData(null);
    }
    const handleShow = () => {
        setShow(true);
        loadData();
    }
    const handleChange = (item) => {
        console.log(item);
        let newdata = [];
        for (var ti of data) {
            if (ti.id === item.id) {
                newdata.push(item);
            } else {
                newdata.push(ti);
            }
        }
        setData(newdata);
        saveItem(item);
    }
    return (
        <>
            <Row>
                <Col>Edit translation phrases</Col>
                <Col xs="4">
                    <Button variant="success" size="sm" onClick={handleShow} >
                        Edit
                    </Button>
                </Col>
            </Row>
            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Translations</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            Changes are automatically saved.
                            <Row>
                                <Col>Default En</Col>
                                <Col>Alternate En</Col>
                                <Col>Cymraeg</Col>
                                <Col></Col>
                            </Row>
                            {data.map((translation, index) => (
                                <TranslationItem value={translation} key={index} onChange={ handleChange } />
                            ))}
                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default EditTranslationsPopup;