import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MeetingCalendar from "../components/calendar/MeetingCalendar";
import Meeting from "../components/meeting/Meeting";
import { useNavigate } from "react-router-dom";
import OutsideBodiesPage from "../components/outsidebody/OutsideBodyPage";
import Container from 'react-bootstrap/Container';

export default function OutsideBodiesRoute() {

    const [selectedOutsideBody, setSelectedOutsideBody] = useState(null);
    const navigate = useNavigate();

    const onSelectOutsideBody = (eventitem) => {
        //console.log(eventitem.id);
        //setSelectedMeeting(eventitem.id);
        navigate("/outsidebody/" + eventitem.id);
    }


    return (
        <>
            <Container>
                <OutsideBodiesPage></OutsideBodiesPage>
            </Container>
        </>
    );
}