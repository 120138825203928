import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useTranslation } from 'react-i18next';
import LanguageSelector from "./LanguageSelector";
import { Link, NavLink } from "react-router-dom";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import logo from "../logowhite.png";

function SiteNav() {
    const { t, i18n } = useTranslation();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    return (
        <>
            {[/*false, 'sm', 'md', 'lg', 'xl', 'xxl'*/'lg'].map((expand) => (
                <Navbar key={expand} expand={expand} className="nav navbar-dark mb-3">
                    <Container fluid>
                        <Navbar.Brand as={Link} to="/">
                            <img src={logo} height="30" class="d-inline-block"/> BBNPA Governance
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                        <Navbar.Offcanvas
                            id={`offcanvasNavbar-expand-${expand}`}
                            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                            placement="end"
                        >
                            <Offcanvas.Header closeVariant='white' closeButton>
                                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                                    BBNPA Governance
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <Nav className="justify-content-start navbar-dark flex-grow-1 pe-3">
                                    <Nav.Link as={Link} to="/calendar">{t("Calendar")}</Nav.Link>
                                    <Nav.Link as={Link} to="/committees">{t("Committees")}</Nav.Link>
                                    <Nav.Link as={Link} to="/library">{t("Library")}</Nav.Link>
                                    {/*<Nav.Link as={Link} to="/meetings">{t("Meetings")}</Nav.Link>*/}
                                    <Nav.Link as={Link} to="/outsidebodies">{t("Outside Bodies")}</Nav.Link>
                                    <Nav.Link as={Link} to="/members">{t("Your Members")}</Nav.Link>
                                    <Nav.Link as={Link} to="/contact">{t("Contact")}</Nav.Link>
                                    {isAuthenticated ? (
                                        <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
                                    ) : ''}
                                    {/*<NavDropdown*/}
                                    {/*    title="Dropdown"*/}
                                    {/*    id={`offcanvasNavbarDropdown-expand-${expand}`}*/}
                                    {/*>*/}
                                    {/*    <NavDropdown.Item href="#action3">Action</NavDropdown.Item>*/}
                                    {/*    <NavDropdown.Item href="#action4">*/}
                                    {/*        Another action*/}
                                    {/*    </NavDropdown.Item>*/}
                                    {/*    <NavDropdown.Divider />*/}
                                    {/*    <NavDropdown.Item href="#action5">*/}
                                    {/*        Something else here*/}
                                    {/*    </NavDropdown.Item>*/}
                                    {/*</NavDropdown>*/}
                                </Nav>
                                
                                <Form className="d-flex">
                                    <LanguageSelector></LanguageSelector>&nbsp;
                                    {/*<Form.Control*/}
                                    {/*    type="search"*/}
                                    {/*    placeholder="Search"*/}
                                    {/*    className="me-2"*/}
                                    {/*    aria-label="Search"*/}
                                    {/*/>*/}
                                    {/*<Button variant="outline-success">Search</Button>*/}
                                </Form>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </>
    );
}

export default SiteNav;