import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import {
    format,
    subHours,
    startOfMonth,
    endOfMonth,
    formatISO,
    subMonths,
    getYear,
    addMonths,
    parseISO,
    parse,
    toDate
} from 'date-fns';
import { enGB, cy } from 'date-fns/locale';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { DatePicker } from '@mui/x-date-pickers';

import { delocalizedIsoDate, isValidDate, uuidv4 } from '../Util.js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import LibraryFileEdit from "./LibraryFileEditor";

export default function LibraryFolderPopup(props) {

    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    //const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);
    const [libraryfolder, setLibraryfolder] = useState(null);
    const [page, setPage] = useState("details");

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getLibraryFolder + "/" + props.id)
                                .then(function (response) {
                                    console.log(response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }
    function saveData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .put(authConfig.api.urls.getLibraryFolder + "/" + data.id, data)
                                .then(function (response) {
                                    //console.log(response);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }
    function addData() {
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .post(authConfig.api.urls.getLibraryFolder, data)
                                .then(function (response) {
                                    //console.log(response);
                                    if (props.onChange) {
                                        props.onChange();
                                    }
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }

    function removeData() {
        console.log("del 1")
        setError(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .delete(authConfig.api.urls.getLibraryFolder + "/" + props.id)
                                .then(function (response) {
                                    //console.log(response);
                                    if (props.onChange) {
                                        props.onChange();
                                    }
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false);
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    });

            } else {
                setError("No account...")
            }
        }
    }

    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }
    const handleClose = () => {
        setShow(false);
        setData(null);
        if (props.onChange) {
            props.onChange();
        }
    }
    const handleShow = () => {
        setShow(true);
        if (props.id == null) {
            setData({
                id: uuidv4(),
                "nameEn": "",
                "nameCy": "",
                "descriptionEn": "",
                "descriptionCy": "",
                "files": [],
                "folders": [],
                "parentId": props.parentId,
            });
        } else {
            loadData();
        }

    }
    const handleSave = () => {
        if (props.id == null) {
            addData();
            handleClose();
        } else {
            saveData();
            handleClose();
        }
    }
    const handleRemove = () => {
        removeData();
       
    }

    /*const handleSelectOutsideBody = (o) => {
        setLibraryfolder(o);
    }*/



    const handlePageChange = (p) => {
        setPage(p);
    }
    const handleRepresentativesChange = (o) => {
        data.resentatives = o;
        setData({ ...data });
    }
    /*
     * 
     * */

    const handleDescriptionChange = (html, lang) => {
        if (lang === "en") { data.descriptionEn = html; }
        if (lang === "cy") { data.descriptionCy = html; }
        setData({ ...data });
    }
    const handleContactChange = (html, lang) => {
        if (lang === "en") { data.contactEn = html; }
        if (lang === "cy") { data.contactCy = html; }
        setData({ ...data });
    }
    const handleNameEnChange = (e) => {
        data.nameEn = e.target.value;
        setData({ ...data });
    }
    const handleNameCyChange = (e) => {
        data.nameCy = e.target.value;
        setData({ ...data });
    }
    
    const handleAddLibraryFile = () => {
        data.files.push({
            id: uuidv4(),
            nameEn: "",
            nameCy: "",
            attachedFileEn: null,
            attachedFileCy: null,
        });
        setData({ ...data });
    }
    const handleFileChange = (o) => {
        let newfiles = [];
        for (var file of data.files) {
            if (file.id === o.id) {
                newfiles.push(o);
            } else {
                newfiles.push(file);
            }
        }
        data.files = newfiles;
        setData({ ...data });
    }
    const handleFileRemove = (o) => {
        console.log("remove", o);
        let newfiles = [];
        for (var file of data.files) {
            if (file.id === o.id) {
               //skip
            } else {
                newfiles.push(file);
            }
        }
        data.files = newfiles;
        setData({ ...data });
    }
    /*
     * 
     * */
    return (
        <>
            {
                props.id ? (
                    <>
                        <Button size="sm" variant="success" onClick={handleShow}>
                            <FaEdit />
                        </Button>&nbsp;
                        <Button size="sm" variant="danger" onClick={handleRemove}>
                            <FaTrashAlt />
                        </Button>
                    </>
                ) :
                    (
                        <Button variant="success" onClick={handleShow}>
                            Add Folder
                        </Button>
                    )
            }


            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.id == null ? "Add Folder" : "Edit Folder "} - {data == null ? '' : data.nameEn}</Modal.Title>
                </Modal.Header>
                {data == null ? '' :
                    (
                        <Modal.Body>
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldtype-1`}
                                    type="radio"
                                    name="radio"
                                    value="details"
                                    checked={page === "details"}
                                    onClick={() => { handlePageChange("details"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Folder Details
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldtype-2`}
                                    type="radio"
                                    name="fieldtype"
                                    value="description"
                                    checked={page === "description"}
                                    onClick={() => { handlePageChange("description"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Description
                                </ToggleButton>
                                <ToggleButton
                                    key={3}
                                    id={`fieldtype-3`}
                                    type="radio"
                                    name="fieldtype"
                                    value="files"
                                    checked={page === "files"}
                                    onClick={() => { handlePageChange("files"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Files
                                </ToggleButton>
                            </ButtonGroup>
                            &nbsp;
                            <ButtonGroup className="mb-2">
                                <ToggleButton
                                    key={1}
                                    id={`fieldlang-1`}
                                    type="radio"
                                    name="fieldlang"
                                    value="en"
                                    checked={language === "en"}
                                    onClick={() => { handleLanguageChange("en"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    English
                                </ToggleButton>
                                <ToggleButton
                                    key={2}
                                    id={`fieldlang-2`}
                                    type="radio"
                                    name="fieldlang"
                                    value="cy"
                                    checked={language === "cy"}
                                    onClick={() => { handleLanguageChange("cy"); }}
                                    size="sm"
                                    variant="success"
                                >
                                    Cymraeg
                                </ToggleButton>
                            </ButtonGroup>
                            <Container style={{ display: language === 'cy' ? "none" : "" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (en)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameEn} onChange={handleNameEnChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionEn}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'en');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "files" ? '' : 'none' }}>

                                </div>
                            </Container>
                            <Container style={{ display: language === 'cy' ? "" : "none" }}>
                                <div style={{ display: page === "details" ? '' : 'none' }}>
                                    <Row>
                                        <Col xs="4">
                                            Name (cy)
                                        </Col>
                                        <Col xs="8">
                                            <Form.Control type="text" value={data.nameCy} onChange={handleNameCyChange} />
                                        </Col>
                                    </Row>
                                </div>
                                <div style={{ display: page === "description" ? '' : 'none' }}>
                                    <CKEditor
                                        editor={Editor}
                                        data={data.descriptionCy}
                                        onReady={editor => {
                                            // You can store the "editor" and use when it is needed.
                                            //console.log('Editor is ready to use!', editor);
                                        }}
                                        onChange={(event, editor) => {
                                            const data = editor.getData();
                                            handleDescriptionChange(data, 'cy');
                                            //console.log({ event, editor, data });
                                        }}
                                        onBlur={(event, editor) => {
                                            //console.log('Blur.', editor);
                                        }}
                                        onFocus={(event, editor) => {
                                            //console.log('Focus.', editor);
                                        }}
                                    />
                                </div>
                                <div style={{ display: page === "files" ? '' : 'none' }}>

                                </div>
                            </Container>
                            <div style={{ display: page === "files" ? '' : 'none' }}>
                                <Button onClick={handleAddLibraryFile}>Add File</Button>
                                {data.files.map((file, index) => (
                                    <LibraryFileEdit value={file} onChange={handleFileChange} onRemove={ handleFileRemove } />
                                    ))}
                            </div>


                        </Modal.Body>
                    )}
                <Modal.Footer>
                    <Button variant="success" onClick={handleSave}>
                        Save
                    </Button>
                    <Button variant="danger" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}