import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';
import authConfig from "../../authConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import { uuidv4 } from '../Util.js';

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react";

function MemberPublicFilesEditor(props) {
    const { t, i18n } = useTranslation();

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})


    //const language = useSelector((state) => state.language.value);

    const handleMoveUp = (o) => {
        let d = props.data.sort((a, b) => { return (a.positionIndex > b.positionIndex) ? 1 : -1 });
        let newdata = [];
        for (var i = 0; i < d.length; i++) {
            if (i + 1 < d.length && d[i + 1].id === o.id) {
                newdata.push(o);
                newdata.push(d[i]);
                i++;
            } else {
                newdata.push(d[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleMoveDown = (o) => {
        let d = props.data.sort((a, b) => { return (a.positionIndex > b.positionIndex) ? 1 : -1 });
        let newdata = [];
        for (var i = 0; i < d.length; i++) {
            if (d[i].id === o.id && i + 1 < d.length) {
                newdata.push(d[i + 1]);
                newdata.push(o);
                i++;
            } else {
                newdata.push(d[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleRemove = (o) => {
        let d = props.data.sort((a, b) => { return (a.positionIndex > b.positionIndex) ? 1 : -1 });
        let newdata = [];
        for (var i = 0; i < d.length; i++) {
            if (d[i].id === o.id) {
                // skip
            } else {
                newdata.push(d[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    async function handleUpload(e) {
        if (e.target.value) {
            const formdata = new FormData();
            formdata.append('file', e.target.files[0]);
            formdata.append('id', uuidv4());

            if (e.target.files[0].type === ("application/pdf")) {
                console.log(e.target.files[0]);
                let authresponse = null;
                //setLoading(true);
                if (isAuthenticated) {
                    if (account) {
                        //setLoading(true)
                        await instance
                            .acquireTokenSilent({
                                scopes: authConfig.api.scopes,
                                account: account
                            })
                            .then(response => {
                                authresponse = response;
                            })
                            .catch(error => {
                                //setError("Error getting token: " + error.message)
                                //console.error(error)
                                //setLoading(false);
                            });
                    }
                } 
                (async () => {
                    let headers = new Headers();
                    if (authresponse) {
                        headers.append('Authorization', 'Bearer ' + authresponse.accessToken);
                    }
                    const response = await fetch(authConfig.api.urls.getAgendaItemUploadFile, {
                        method: "post",
                        headers,/*headers: { 'Content-Type': 'multipart/form-data' },*/
                        body: formdata
                    });
                    if (response.ok) {
                        const jsonData = await response.json();
                        addFile(jsonData);
                    }
                    

                })();
            }

            e.target.value = null;
        }
    }
    const handleNameChange = (o, e) => {
        let d = props.data.sort((a, b) => { return (a.positionIndex > b.positionIndex) ? 1 : -1 });
        let newdata = [];
        for (var i = 0; i < d.length; i++) {
            if (d[i].id === o.id) {
                d[i].attachedFile.name = e.target.value;
            } 
            newdata.push(d[i]);
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleCategoryChange = (o, e) => {
        let d = props.data.sort((a, b) => { return (a.positionIndex > b.positionIndex) ? 1 : -1 });
        let newdata = [];
        for (var i = 0; i < d.length; i++) {
            if (d[i].id === o.id) {
                d[i].category = e.target.value;
            }
            newdata.push(d[i]);
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    
    const addFile = (attachmentjson) => {
        let newdata = [...props.data, {
            id: uuidv4(),
            attachedFile: attachmentjson,
            category: "",
            positionIndex: props.data.length + 1,
            member:null
        }];
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    //<li key={index}>{file.attachedFile.name}</li>
    return (
        <>
            <Form.Group controlId="formFileMultiple" className="mb-3">
                <Form.Label>Add PDF file ({props.language})</Form.Label>
                <Form.Control type="file" accept=".pdf" onChange={handleUpload} />
            </Form.Group>


            {
                props.data ? props.data.sort((a, b) => {
                    return (a.positionIndex > b.positionIndex) ? 1 : -1
                }).map((file, index) => (
                    <Row key={index}>
                        <Col xs="4">
                            Category <Form.Control type="text" value={file.category} onChange={(e) => { handleCategoryChange(file, e) }} />
                        </Col>
                        <Col xs="4">
                            File Name <Form.Control type="text" value={file.attachedFile.name} onChange={(e) => { handleNameChange(file,e)} } />
                        </Col>
                        <Col xs="2">
                            <Button size="sm" variant="success"
                                onClick={() => { handleMoveDown(file); }}>
                                <FaAngleDown />
                            </Button>&nbsp;
                            <Button size="sm" variant="success"
                                onClick={() => { handleMoveUp(file); }}>
                                <FaAngleUp />
                            </Button>&nbsp;
                        </Col>
                        <Col xs="1">
                            <Button size="sm" variant="danger"
                                onClick={() => { handleRemove(file); }}>
                                <FaTrashAlt />
                            </Button>
                        </Col>
                    </Row>
                )) : ''
            }

        </>
    )



}
export default MemberPublicFilesEditor;