import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';
import authConfig from "../../authConfig";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SecureFileLink from "./SecureFileLink";
function AgendaItemFiles(props) {
    const { t, i18n } = useTranslation();
    const language = useSelector((state) => state.language.value);




    return (
        <>
            
            {language === 'cy' ?
                (
                    <>
                        {props.agendaitem.attachedFilesCy && props.agendaitem.attachedFilesCy.length > 0 ? (<div>{t("Additional Files")}</div>) : ''}
                        {
                            props.agendaitem.attachedFilesCy ?
                                props.agendaitem.attachedFilesCy.sort((a, b) => {
                                    return (a.positionIndex > b.positionIndex) ? 1 : -1
                                }).map((file, index) => (

                                    <li key={index}>
                                        {
                                            file.isRestricted ?
                                                (
                                                    <SecureFileLink
                                                        url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                                        filename={file.attachedFile.fileName}>{file.attachedFile.name}
                                                    </SecureFileLink>
                                                ) :
                                                (
                                                    <SecureFileLink
                                                        url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                                        filename={file.attachedFile.fileName}>{file.attachedFile.name}
                                                    </SecureFileLink>
                                                )}
                                    </li>
                                )) : ''
                        }
                    </>
                )
                :
                (
                    <>
                        {props.agendaitem.attachedFilesEn && props.agendaitem.attachedFilesEn.length > 0 ?
                            (<div>{t("Additional Files")}</div>) : ''}
                        {
                            props.agendaitem.attachedFilesEn ? props.agendaitem.attachedFilesEn.sort((a, b) => {
                                return (a.positionIndex > b.positionIndex) ? 1 : -1
                            })
                                .map((file, index) => (
                                    <li key={index}>
                                        {
                                            file.isRestricted ?
                                                (
                                                    <SecureFileLink
                                                        url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                                        filename={file.attachedFile.fileName}>{file.attachedFile.name}
                                                    </SecureFileLink>
                                                ) :
                                                (
                                                    <SecureFileLink
                                                        url={authConfig.api.urls.getMeetingFile + "/" + file.id + "/" + file.attachedFile.fileName}
                                                        filename={file.attachedFile.fileName}>{file.attachedFile.name}
                                                    </SecureFileLink>
                                                )}

                                    </li>
                                )) : ''
                        }
                    </>
                )
            }

        </>
    )



}
export default AgendaItemFiles;