import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { Link, NavLink } from "react-router-dom";
import AgendaItemEditor from "./AgendaItemEditor";

import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"
import authConfig from "../../authConfig";
import MeetingAgendaItemClonePopup from "./MeetingAgendaItemClonePopup";
function AgendaEditor(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    const [show, setShow] = useState(false);

    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    async function getNewItemAsync() {
        let returnvalue = {};
        let authresponse = null;


        if (isAuthenticated) {
            if (account /*&& loading == false*/) {
                //setLoading(true)
                await instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        authresponse = response;
                    })
                    .catch(error => {
                        //setError("Error getting token: " + error.message)
                        console.error(error)
                        //setLoading(false)
                    });
                if (authresponse) {
                    //console.log("Got access token:", authresponse);
                    await axios
                        .create({
                            headers: { Authorization: `Bearer ${authresponse.accessToken}` }
                        })
                        .get(authConfig.api.urls.getAgendaItemNew)
                        .then(function (response) {
                            //console.log(response.data);
                            returnvalue = response.data;
                            return response.data;
                        })
                        .catch(function (error) {
                            console.error(error)
                        })
                        .then(function () {
                            // always executed
                            //setLoading(false)
                        })
                } else {
                    //setError("Empty token??")
                    console.error("no token acquired")
                }
            }
        }
        return returnvalue;
    }


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangeLanguage = (lang) => {
        setLanguage(lang);
    }
    async function handleAdd()  {
        const newitem = await getNewItemAsync();
        let newdata = [...props.data];
        newdata.push(newitem);
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleOnChange = (item) => {
        let newdata = [];
        for (var o of props.data) {
            if (o.id === item.id) {
                newdata.push(item);
            } else {
                newdata.push(o);
            }
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleMoveUp = (o) => {
        let newdata = [];
        for (var i = 0; i < props.data.length; i++) {
            if (i + 1 < props.data.length && props.data[i + 1].id === o.id) {
                newdata.push(o);
                newdata.push(props.data[i]);
                i++;
            } else {
                newdata.push(props.data[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleMoveDown = (o) => {
        let newdata = [];
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i].id === o.id && i + 1 < props.data.length) {
                newdata.push(props.data[i + 1]);
                newdata.push(o);
                i++;
            } else {
                newdata.push(props.data[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
        //console.log("pd,nd = ",props.data.length, ",",newdata.length);
    }
    async function handleInsert(o)  {
        const newitem = await getNewItemAsync();
        let newdata = [];
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i].id === o.id) {
                newdata.push(newitem);
                newdata.push(o);
            } else {
                newdata.push(props.data[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleRemove = (o) => {
        let newdata = [];
        for (var i = 0; i < props.data.length; i++) {
            if (props.data[i].id === o.id) {
                // skip
            } else {
                newdata.push(props.data[i]);
            }
        }
        for (var i = 0; i < newdata.length; i++) {
            newdata[i].positionIndex = i;
        }
        if (props.onChange) {
            props.onChange(newdata);
        }
    }
    const handleCloneAgenda = (o) => {
        if (props.onCloneAgendaAndAttendees) {
            props.onCloneAgendaAndAttendees(o);
        }
    }
    return (
        <>
            <ButtonGroup className="mb-2">
                <Button size="sm" variant="success" onClick={handleAdd}>
                    Add Agenda Item
                </Button>
            </ButtonGroup>
            &nbsp;
            <ButtonGroup className="mb-2">
                <MeetingAgendaItemClonePopup committee={props.committee} onChange={handleCloneAgenda} />
            </ButtonGroup>
            &nbsp;
            <ButtonGroup className="mb-2">
                <ToggleButton
                    key={1}
                    id={`radio-1`}
                    type="radio"
                    name="radio"
                    value="en"
                    checked={language === "en"}
                    onClick={() => { handleChangeLanguage("en"); }}
                    size="sm"
                    variant="success"
                >
                    English
                </ToggleButton>
                <ToggleButton
                    key={2}
                    id={`radio-2`}
                    type="radio"
                    name="radio"
                    value="cy"
                    checked={language === "cy"}
                    onClick={() => { handleChangeLanguage("cy"); }}
                    size="sm"
                    variant="success"
                >
                    Cymraeg
                </ToggleButton>
            </ButtonGroup>
            {props.data ? props.data.map((item, index) => (
                <AgendaItemEditor
                    data={item}
                    key={index}
                    language={language}
                    onChange={handleOnChange}
                    onMoveUp={handleMoveUp}
                    onMoveDown={handleMoveDown}
                    onInsert={handleInsert}
                    onRemove={handleRemove}
                />
            )) : ''}
        </>);
}

export default AgendaEditor;