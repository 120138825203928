import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios"
import authConfig from "../../authConfig";
import { useSelector, useDispatch } from 'react-redux';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
    useIsAuthenticated,
    useAccount
} from "@azure/msal-react"

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import MeetingSelector from "./MeetingSelector";
import Meeting from "./Meeting";
import { uuidv4 } from "../Util";


function MeetingAgendaItemClonePopup(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    //const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [data, setData] = useState(null);


    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts } = useMsal()
    const account = useAccount(accounts[0] || {})

    const [selectedItems, setSelectedItems] = useState([]);
    const [includeAttendees, setIncludeAttendees] = useState(false);
    const [includeAttachedFiles, setIncludeAttachedFiles] = useState(false);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function loadData() {
        setError(null)
        setData(null)
        if (isAuthenticated) {
            if (account && loading == false) {
                setLoading(true)
                instance
                    .acquireTokenSilent({
                        scopes: authConfig.api.scopes,
                        account: account
                    })
                    .then(response => {
                        if (response) {
                            //console.log("Got access token:", response)
                            axios
                                .create({
                                    headers: { Authorization: `Bearer ${response.accessToken}` }
                                })
                                .get(authConfig.api.urls.getMeeting + "/" + selectedMeeting.id)
                                .then(function (response) {
                                    console.log("RD=",response.data);
                                    //response.data.agendaItems.sort((a, b) => {
                                    //    return a.positionIndex - b.positionIndex;
                                    //});
                                    setData(response.data);
                                })
                                .catch(function (error) {
                                    setError("Error fetching data: " + error.message)
                                    console.error(error)
                                })
                                .then(function () {
                                    // always executed
                                    setLoading(false)
                                })
                        } else {
                            setError("Empty token??")
                            console.error("no token acquired")
                        }
                    })
                    .catch(error => {
                        setError("Error getting token: " + error.message)
                        console.error(error)
                        setLoading(false)
                    })
            } else {
                setError("No account...")
            }
        }
    }


    const handleSelectedMeetingChange = (o) => {
        setSelectedMeeting(o);
    }
    const handleLoadData = () => {
        loadData();
    }
    const handleShow = () => {
        setSelectedItems([]);
        setData(null);
        setSelectedMeeting(null);
        setIncludeAttachedFiles(false);
        setIncludeAttendees(false);
        setShow(true);
    }
    const handleSave = () => {
        let agendaItemsToCopy = [];
        
        for (var ai of data.agendaItems) {
            
            if (selectedItems.includes(ai.positionIndex)) {
                let aicopy = { ...ai };
                aicopy.id = uuidv4();
                aicopy.attachedFilesEn = [];
                aicopy.attachedFilesCy = [];
                if (includeAttachedFiles) {
                    for (var f of ai.attachedFilesEn) {
                        f.id = uuidv4();
                        aicopy.attachedFilesEn.push(f);
                    }
                    for (var f of ai.attachedFilesCy) {
                        f.id = uuidv4();
                        aicopy.attachedFilesCy.push(f);
                    }
                }
                agendaItemsToCopy.push(aicopy);
            }
        }
        let attendeesToCopy = [];
        if (includeAttendees) {
            //console.log("atts=", data.attendees);
            for (var a of data.attendees) {
                console.log("att=",a)
                let acopy = { ...a };
                acopy.id = uuidv4();
                attendeesToCopy.push(acopy);
            }
        }
        
        if (props.onChange) {
            props.onChange({ attendees: attendeesToCopy, agendaItems: agendaItemsToCopy });
        }
        setShow(false);
    }
    const handleSelectedItemChange = (pi) => {
        let newSelectedItems = [];
        if (selectedItems.includes(pi)) {
            for (var i of selectedItems) {
                if (i === pi) { }
                else { newSelectedItems.push(i); }
            }
        } else {
            newSelectedItems = [...selectedItems, pi];
        }
        console.log(pi, selectedItems, newSelectedItems);
        setSelectedItems(newSelectedItems);
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleIncludeAttendeesChange = () => {
        setIncludeAttendees(!includeAttendees);
    }
    const handleIncludeAttachedFiles = () => {
        setIncludeAttachedFiles(!includeAttachedFiles);
    }
    return (
        <>
            <Button variant="success" size="sm" onClick={handleShow} >Copy Previous Agenda</Button>
            <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} animation={false} enforceFocus={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Copy Agenda Items</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col xs="2">
                            Meeting
                        </Col>
                        <Col xs="8">
                            <MeetingSelector committee={props.committee} value={selectedMeeting} onChange={handleSelectedMeetingChange} />
                        </Col>
                        <Col>
                            <Button onClick={handleLoadData} variant="success" >Load Agenda</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            Include Attendees
                        </Col>
                        <Col xs="8">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="includeattendeesswitch"
                                label={includeAttendees ? "Include" : "Exclude"}
                                checked={includeAttendees}
                                onChange={handleIncludeAttendeesChange}
                            />
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs="2">
                            Include Attached Files
                        </Col>
                        <Col xs="8">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="includeattachedfilesswitch"
                                label={includeAttachedFiles ? "Include" : "Exclude"}
                                checked={includeAttachedFiles}
                                onChange={handleIncludeAttachedFiles}
                            />
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                    
                        
                        {data == null ? '' :
                            (
                                <>{data.agendaItems.sort((a, b) => { return a.positionIndex > b.positionIndex ? 1 : -1 }).map((ai, index) => (
                                    <Row>
                                        <Col xs="1">{ai.tagEn}</Col>
                                        <Col>{ai.nameEn}</Col>
                                        <Col>
                                            <Form.Check // prettier-ignore
                                                type="switch"
                                                id="includeagendaitemswitch"
                                                label={selectedItems.includes(ai.positionIndex) ? "Include" : "Exclude"}
                                                checked={selectedItems.includes(ai.positionIndex)}
                                                onChange={() => { handleSelectedItemChange(ai.positionIndex); }}
                                            />
                                        </Col>
                                    </Row>
                                ))}</>
                            )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="success" onClick={handleSave}>
                            Copy
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Modal.Footer>
            </Modal>
        </>
    )
}
export default MeetingAgendaItemClonePopup;