import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import authConfig from "../../authConfig";
import axios from "axios"
import { useSelector, useDispatch } from 'react-redux';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';

import AgendaItemFiles from './AgendaItemFiles'
import { FaEdit, FaAngleDown, FaAngleUp, FaPlus, FaTrashAlt } from 'react-icons/fa';
import Autocomplete from "../autocomplete/Autocomplete";

import { CKEditor } from "@ckeditor/ckeditor5-react";
//import Editor from "ckeditor5-custom-inline/build/ckeditor";
import Editor from "ckeditor5-custom-classic/build/ckeditor";
import AgendaItemFileEditor from "./AgendaItemFileEditor";
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
//import InlineEditor from '@ckeditor/ckeditor5-build-inline';

function AgendaItemEditor(props) {
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState("en"); //useSelector((state) => state.language.value);
    const [show, setShow] = useState(false);
    const [showTools, setShowTools] = useState(false);
    const [showRemoveWarning, setShowRemoveWarning] = useState(false);
    const [page, setPage] = useState("description");
    const handleToolsEnter = () => setShowTools(true);
    const handleToolsLeave = () => setShowTools(false);

    const handleMoveUp = () => {
        if (props.onMoveUp) {
            props.onMoveUp(props.data);
        }
    }
    const handleMoveDown = () => {
        if (props.onMoveDown) {
            props.onMoveDown(props.data);
        }
    }
    const handleInsert = () => {
        if (props.onInsert) {
            props.onInsert(props.data);
        }
    }
    const handleRemove = () => {
        setShowRemoveWarning(true);
        //if (props.onRemove) {
        //    props.onRemove(props.data);
        //}
    }
    function htmlDecode(input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    }
    const handleClose = () => {
        setShow(false);
        //setData([]);
    }
    const handleShow = () => {
        setShow(true);
        //loadData();
    }
    const handlePageChange = (pagename) => {
        setPage(pagename);
    }
    const handleLanguageChange = (lang) => {
        setLanguage(lang);
    }
    const handleEditItem = () => {
        setShow(true);
    }
    const handleTagChange = (e) => {
        let data = props.data;
        data.tagEn = e.target.value;
        if (props.onChange) {
            props.onChange(data);
        }
    }
    
    const handleNameChangeEn = (o) => {
        //console.log(o);
        let data = props.data;
        data.nameEn = o.en;
        if (o.cy) {
            data.nameCy = o.cy;
        }
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleNameChangeCy = (e) => {
        let data = props.data;
        data.nameCy = e.target.value;
        if (props.onChange) {
            //console.log("data,index = ", data, props.key)
            props.onChange(data);
        }
    }
    const handleDescriptionChange = (html, lang) => {
        let data = props.data;
        if (lang === "en") { data.descriptionEn = html; }
        if (lang === "cy") { data.descriptionCy = html; }
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleMinutesChange = (html, lang) => {
        let data = props.data;
        if (lang === "en") { data.minutesEn = html; }
        if (lang === "cy") { data.minutesCy = html; }
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleDecisionChange = (html, lang) => {
        let data = props.data;
        if (lang === "en") { data.decisionEn = html; }
        if (lang === "cy") { data.decisionCy = html; }
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleIsRestrictedChange = (e) => {
        let data = props.data;
        data.restricted = e.target.checked;
        if (props.onChange) {
            props.onChange(data);
        }
        if (page === "restrictedreason" && !e.target.checked) {
            setPage("description");
        }
    }
    const handleRestrictedReasonChange = (html, lang) => {
        let data = props.data;
        if (lang === "en") { data.restrictedReasonEn = html; }
        if (lang === "cy") { data.restrictedReasonCy = html; }
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleRemoveWarningClose = () => {
        setShowRemoveWarning(false);
    }
    const handleRemoveWarningConfirm = () => {
        setShowRemoveWarning(false);
        if (props.onRemove) {
            props.onRemove(props.data);
        }
    }
    const handleAttachedFilesEnChange = (o) => {
        let data = props.data;
        data.attachedFilesEn = o;
        if (props.onChange) {
            props.onChange(data);
        }
    }
    const handleAttachedFilesCyChange = (o) => {
        let data = props.data;
        data.attachedFilesCy = o;
        if (props.onChange) {
            props.onChange(data);
        }
    }
    return (<>
        <Row>
            <Col xs={1} md={1} onMouseEnter={handleToolsEnter} onMouseLeave={handleToolsLeave} >
                <Button variant="success" size="sm" onClick={handleEditItem}><FaEdit /></Button>
                <div style={{ display: showTools ? '' : 'none' }} >
                    {props.data.positionIndex > 0 ? (
                        <Button variant="success" size="sm" onClick={handleMoveUp}><FaAngleUp /></Button>
                    ) : ''}
                    <Button variant="success" size="sm" onClick={handleMoveDown}><FaAngleDown /></Button>
                    <Button variant="success" size="sm" onClick={handleInsert}><FaPlus /></Button>
                    <Button variant="danger" size="sm" onClick={handleRemove}><FaTrashAlt /></Button>
                </div>
            </Col>
            <Col xs={1} md={1}>{props.data.tagEn}</Col>
            <Col xs={10} md={10}>
                <>
                    <div>
                        <b>{props.language === 'cy' ? props.data.nameCy : props.data.nameEn}  </b>
                    </div>
                    <AgendaItemFiles agendaitem={props.data} />
                    <div dangerouslySetInnerHTML={{ __html: props.language === 'cy' ? props.data.descriptionCy : props.data.descriptionEn }} />
                    {
                        props.language === 'cy' ? (<>
                            {
                                props.data.minutesCy ? (
                                    <>
                                        <div><b>{t("Minutes")}</b></div>
                                        <div dangerouslySetInnerHTML={{ __html: props.data.minutesCy }} />
                                    </>

                                ) : (<></>)
                            }</>) :
                            (<>
                                {
                                    props.data.minutesEn ? (
                                        <>
                                            <div><b>Minutes</b></div>
                                            <div dangerouslySetInnerHTML={{ __html: props.data.minutesEn }} />
                                        </>
                                    ) : (<></>)
                                }</>)
                    }

                </>
            </Col>
        </Row>
        <hr />
        <Modal show={show} onHide={handleClose} size="xl" autoFocus={false} enforceFocus={false}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Agenda Item {props.data.positionIndex + 1}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col xs="2">Item Number/Tag</Col>
                    <Col xs="10">
                        <Form.Control type="text" name="nameEn" value={props.data.tagEn} onChange={handleTagChange} />
                    </Col>
                </Row>
                <Row>
                    <Col xs="2">
                        Restricted from public
                    </Col>
                    <Col>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="restricted-switch"
                            label={props.data.restricted ? "Restricted" : "Not Restricted"}
                            checked={props.data.restricted}
                            onChange={handleIsRestrictedChange}
                        />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <ButtonGroup className="mb-2">
                    <ToggleButton
                        key={1}
                        id={`fieldtype-1`}
                        type="radio"
                        name="radio"
                        value="description"
                        checked={page === "description"}
                        onClick={() => { handlePageChange("description"); }}
                        size="sm"
                        variant="success"
                    >
                        Description
                    </ToggleButton>
                    <ToggleButton
                        key={2}
                        id={`fieldtype-2`}
                        type="radio"
                        name="fieldtype"
                        value="minutes"
                        checked={page === "minutes"}
                        onClick={() => { handlePageChange("minutes"); }}
                        size="sm"
                        variant="success"
                    >
                        Minutes
                    </ToggleButton>
                    <ToggleButton
                        key={3}
                        id={`fieldtype-3`}
                        type="radio"
                        name="fieldtype"
                        value="restrictedreason"
                        checked={page === "restrictedreason"}
                        onClick={() => { handlePageChange("restrictedreason"); }}
                        size="sm"
                        variant="success"
                        disabled={!props.data.isRestricted}
                    >
                        Restricted Reason
                    </ToggleButton>
                    <ToggleButton
                        key={3}
                        id={`fieldtype-4`}
                        type="radio"
                        name="fieldtype"
                        value="decision"
                        checked={page === "decision"}
                        onClick={() => { handlePageChange("decision"); }}
                        size="sm"
                        variant="success"
                    >
                        Decision
                    </ToggleButton>
                    <ToggleButton
                        key={4}
                        id={`fieldtype-5`}
                        type="radio"
                        name="fieldtype"
                        value="files"
                        checked={page === "files"}
                        onClick={() => { handlePageChange("files"); }}
                        size="sm"
                        variant="success"
                    >
                        Files (PDF)
                    </ToggleButton>
                </ButtonGroup>
                &nbsp;
                <ButtonGroup className="mb-2">
                    <ToggleButton
                        key={1}
                        id={`fieldlang-1`}
                        type="radio"
                        name="fieldlang"
                        value="en"
                        checked={language === "en"}
                        onClick={() => { handleLanguageChange("en"); }}
                        size="sm"
                        variant="success"
                    >
                        English
                    </ToggleButton>
                    <ToggleButton
                        key={2}
                        id={`fieldlang-2`}
                        type="radio"
                        name="fieldlang"
                        value="cy"
                        checked={language === "cy"}
                        onClick={() => { handleLanguageChange("cy"); }}
                        size="sm"
                        variant="success"
                    >
                        Cymraeg
                    </ToggleButton>
                </ButtonGroup>
                <Container style={{ display: language === 'cy' ? "none" : "" }}>
                    <Row>
                        <Col xs="2">Title (En)</Col>
                        <Col><Autocomplete
                            url={authConfig.api.urls.getAgendaItemName}
                            value={props.data.nameEn}
                            onChange={handleNameChangeEn}
                        /></Col>
                    </Row>
                    <div style={{ display: page === "description" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Description (En)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.descriptionEn}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleDescriptionChange(data, 'en');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "minutes" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Minutes (En)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.minutesEn}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleMinutesChange(data, 'en');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "restrictedreason" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Restricted Reason (En)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.restrictedReasonEn}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleRestrictedReasonChange(data, 'en');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "decision" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Decision (En)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.decisionEn}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleDecisionChange(data, 'en');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "files" ? '' : 'none' }}>
                        <AgendaItemFileEditor language="en" data={props.data.attachedFilesEn} onChange={handleAttachedFilesEnChange} />
                    </div>
                </Container>
                <Container style={{ display: language === 'cy' ? "" : "none" }}>
                    <Row>
                        <Col xl="2">Title (Cy)</Col>
                        <Col>
                            <Form.Control type="text" value={props.data.nameCy} onChange={handleNameChangeCy} />
                        </Col>
                    </Row>
                    <div style={{ display: page === "description" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Description (Cy)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.descriptionCy}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleDescriptionChange(data, 'cy');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "minutes" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Minutes (Cy)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.minutesCy}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleMinutesChange(data, 'cy');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "restrictedreason" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Restricted Reason (Cy)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.restrictedReasonCy}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleRestrictedReasonChange(data, 'cys');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "decision" ? '' : 'none' }}>
                        <Row>
                            <Col><b>Decision (Cy)</b></Col>
                        </Row>
                        <CKEditor
                            editor={Editor}
                            data={props.data.decisionCy}
                            onReady={editor => {
                                // You can store the "editor" and use when it is needed.
                                //console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                handleDecisionChange(data, 'cy');
                                //console.log({ event, editor, data });
                            }}
                            onBlur={(event, editor) => {
                                //console.log('Blur.', editor);
                            }}
                            onFocus={(event, editor) => {
                                //console.log('Focus.', editor);
                            }}
                        />
                    </div>
                    <div style={{ display: page === "files" ? '' : 'none' }}>
                        <AgendaItemFileEditor language="cy" data={props.data.attachedFilesCy} onChange={handleAttachedFilesCyChange } />
                    </div>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showRemoveWarning} onHide={handleRemoveWarningClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Remove Agenda Item {props.data.positionIndex + 1} ?</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="danger" onClick={handleRemoveWarningConfirm}>
                    Remove
                </Button>
                <Button variant="success" onClick={handleRemoveWarningClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    </>)
}
export default AgendaItemEditor;