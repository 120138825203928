import { useEffect, useState, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import axios from "axios";
import { useSelector, useDispatch } from 'react-redux';
import MeetingCalendar from "../components/calendar/MeetingCalendar";
import Meeting from "../components/meeting/Meeting";
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
export default function MeetingsRoute() {

    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const navigate = useNavigate();

    const onSelectMeeting = (eventitem) => {
        //console.log(eventitem.id);
        //setSelectedMeeting(eventitem.id);
        navigate("/meeting/" + eventitem.id);
    }


    return (
        <>
            <Container>
                <MeetingCalendar onClick={onSelectMeeting}></MeetingCalendar>
            </Container>
        </>
    );
}